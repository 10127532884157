<template>
  <div class="row justify-content-center question mb-3 text-input">
    <div class="col-12 col-md-6">
      <QuestionLabel :question="question" :input-name="inputName" />
    </div>
    <div class="col-12 col-md-6">
      <div class="input-group">
        <select
            class="form-select p-2 ps-4 form-select-sm"
            :class="{greenBorder: validInput}"
            aria-label=".form-select-sm example"
            :name="inputName ? inputName : ''"
            @change="selectChoice($event)"
            :v-model="selected">
          <option value="" disabled selected>Choisissez dans la liste</option>
          <option
              v-for="value in (question.choices ? question.choices : choices)"
              :key="Object.keys(value)"
              :value="Object.keys(value)"
              :selected="Object.keys(value) == this.selected ? true : false">
            {{ value[Object.keys(value)] }}
          </option>
        </select>
        <i v-if="validInput" class="m-2 bi bi-check-circle-fill validIcon"></i>
        <div class="col-12 text-start form-error form-error-text">
          <div v-if="!validInput && submitted" class="d-inline">
            Ce champ est obligatoire
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import QuestionLabel from "hm-mpe-widgets/src/lib-components/components/Widgets/QuestionLabel";

export default {
  name: "SelectInput",
  components: { QuestionLabel },
  props: {
    question: Object,
    choices: Array,
    validInput: Boolean,
    submitted: Boolean,
    modelValue: String,
    defaultChoice: String,
    inputName : null
  },
  data: function () {
    return {
      selected: null,
    };
  },
  created() {
    this.selected = this.defaultChoice;
  },
  methods: {
    selectChoice(event) {
      this.selected = event.target.value;
      this.$emit("update:modelValue", {id : this.question.id, value : event.target.value});
    },
    forceSelect(value) {
      this.selected = value;
    },
  },
};
</script>
