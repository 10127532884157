<template>

  <div class="needs-price">

    <ViewContainer>
      <template v-slot:viewBody>
        <div class="row mb-5">
          <div class="col-12">
            <div>
              <h3 class="text-md-center title-page" v-html="formTexts.needs_price.title"></h3>
            </div>
            <div v-html="formTexts.needs_price.intro.text"></div>
          </div>
        </div>
      </template>
    </ViewContainer>

    <nav class="navbar offers navbar-light bg-white" :class="{'sticky-top': this.sticky}">
      <div class="container d-block" v-if="this.price.essentielle">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-10">
            <div class="row g-0 g-md-3">
              <OffersCardsDeck v-if="price.equilibre"
                :price="price"
                :job-name="jobName"
              />
            </div>
          </div>
        </div>
      </div>
    </nav>

    <div v-if="!this.garanties || !this.price.essentielle" class="loaderNeedPrice">

      <Loading :active="true"
               :color="'#000000'"
               :is-full-page="true"/>
    </div>
    <div v-else>

      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-10 mt-4">
            <div class="row justify-content-center">
              <div class="col-12">
                <hr>
              </div>
              <div class="col-12 col-md-auto">
                <CyberProtectSwitch :label="formTexts.needs_price.protection_cyber.text" @inputFirst="cyberProtectValue"/>
              </div>

              <div class="col-12">
                <hr>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 mt-4">
            <div class="row justify-content-center">
              <div class="col-12">
                <h3 class="contract-details">Détails des garanties incluses dans votre contrat</h3>
              </div>

              <div class="row col-12 col-md-10 pt-3 pb-3 mb-5 border rounded bg-head-garanties">
                <div class="col 12">
                  <div class="row">
                    <div class="col-4 text-center">
                      <strong class="text-info2">Essentielle</strong>
                    </div>
                    <div class="col-4 text-center">
                      <strong class="text-info2">Equilibre</strong>
                    </div>
                    <div class="col-4 text-center">
                      <strong class="text-info2">Sérénité</strong>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-10" v-if="this.garanties">
                <GaranteesTable :garanties="garanties" :job-name="jobName" :rc="!activity.isMrPro"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid price-resume-container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 col-lg-8 col-xl-5">
            <h4 class="text-center fw-bold">Récapitulatif de votre offre</h4>
            <PriceResume v-if="price.equilibre"
                         :total="this.cyberProtect? (price[selectedOffer].price + 16.35).toFixed(2).toString().replace('.', ',') : price[selectedOffer].price.toFixed(2).toString().replace('.', ',')"
                         :first-price="price[selectedOffer].price.toString().replace('.', ',')"
                         :recap="true"
                         :first-price-text="firstPriceText"
                         :second-price="this.cyberProtect? '16,35' : ''"
                         second-price-text="Protection cyber" />

            <div class="col-12 mt-2">
              <p class="sub-text mb-0">Cette estimation tarifaire est sans engagement elle est faite sous conditions d’acceptation par l’assureur</p>
            </div>
            <div class="row mt-md-4 pt-2">
              <div class="col-12">
                <DevisButton :in-nav="true" send-devis-class="col-12 col-md-6" subscribe-class="col-12 col-md-6 d-none d-lg-block"/>
              </div>
            </div>
          </div>
                <div class="pt-4 text-center fw-bold"  >
          <img class="feather" :src="require('@/assets/svg/Icon feather-arrow-left-faded.svg')"/>
          <a class="back-button-recap ms-2" @click="editSimulation()">Modifier votre simulation</a>
        </div>
        </div>
      </div>

      <CallbackRow/>

      <div class="container" v-if="garanties">
        <div class="row justify-content-center">
          <div class="col-12 mt-4">

            <div class="row doc-panels justify-content-md-center">
              <div class="col-12"><h3>Documents</h3></div>
            </div>
            <div class="row doc-panels justify-content-md-between">
              <div class="col-12 col-md-6 col-lg-4 pb-2">
                <DocPanel doc-title="Conditions générales" :pdf-link="formTexts.needs_price.cg_pdf.file"/>
              </div>
              <div class="col-12 col-md-6 col-lg-4 pb-2">
                <DocPanel doc-title="Plaquette commerciale" :pdf-link="formTexts.needs_price.plaquette_commerciale_pdf.file"/>
              </div>
              <div class="col-12 col-md-6 col-lg-4">
                <DocPanel doc-title="IPID" v-if="getPdfLink(activity.isMrPro ? '_mr_pro' : '_rc')"
                          :pdf-link="getPdfLink(activity.isMrPro ? '_mr_pro' : '_rc')"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterIcons/>

      <PriceNavbar/>

      <ModalSendMail id="modalSendMail" ref="modalSendMail" :confirmAction="true"/>

      <ModalChangeOffer
        id="modalChangeOffer"
        ref="modalChangeOffer"
        :confirmAction="true" />
    </div>
  </div>

</template>

<script>
import { mapState } from "vuex"
import ViewContainer from "@/components/Navigation/ViewContainer";
import PriceNavbar from "@/components/NeedsPrice/PriceNavbar";
import CyberProtectSwitch from "@/components/NeedsPrice/CyberProtectSwitch";
import FooterIcons from "@/components/Navigation/FooterIcons";
import GaranteesTable from "@/components/NeedsPrice/GaranteesTable";
import OffersCardsDeck from "@/components/OffersCardsDeck";
import PriceResume from "../../../node_modules/hm-mpe-widgets/src/lib-components/components/Devis/PriceResume.vue"
import Loading from 'vue-loading-overlay';
import DevisButton from "../../components/NeedsPrice/DevisButton.vue";
import wrapper from "../../main";
import { reloadEvent } from '../../services/tagCo';
import DocPanel from "@/components/NeedsPrice/DocPanel";
import CallbackRow from "@/components/NeedsPrice/CallbackRow";
import ModalSendMail from "@/components/Navigation/ModalSendMail";
import ModalChangeOffer from "@/components/Navigation/ModalChangeOffer.vue";

export default {
  name:"NeedsPrice",
  data: () => {
    return {
      jobName : null,
      cyberProtectValue: false,
      selected: null,
      initSticky : false,
      widthWindow: null,
      mobilSticky: null,
      sticky: false,
      hauteurDesktop: 800,
      hauteurMobil: 1200,
      firstPriceText: "",
    }
  },
  components: {DocPanel,GaranteesTable, FooterIcons, CyberProtectSwitch, PriceNavbar, ViewContainer, OffersCardsDeck, PriceResume, Loading, CallbackRow, DevisButton, ModalSendMail, ModalChangeOffer },
  created() {
    if(this.$store.state.cyberProtect === "oui") {
      this.cyberProtectValue = true
    }
    this.updateValue();
    this.jobName = this.activity.jobFamily
    // this.jobName = 'sb'

    this.widthWindow = window.innerWidth
    if(this.widthWindow < 800){
      this.$store.dispatch('isMobil', true)
    } else {
      this.$store.dispatch('isMobil', false)
    }
    window.addEventListener('scroll', this.scrollEffect);

    // axios.get(process.env.VUE_APP_DRUPAL_URL+'/hm-parcours-web/json').then((r) => {
    //   this.$store.commit('setGaranties', r.data)
    //   // this.initOffersNavbar()
    // }).then(()=> {
      this.$store.dispatch('updateState')
    // })
    if( this.activity.isMrPro == true ){
      this.firstPriceText="Assurance multirisque professionnelle"
    }else{
      this.firstPriceText="Assurance responsabilité civile professionnelle"
    }
    wrapper.setTcVars({
      page_name : 'tarif-affine',
      page_cat1_name : 'recueil-de-besoins',
      page_cat2_name : '',
    });
    reloadEvent('page');
  },
  methods: {
    scrollEffect(){
      if(this.isMobil) {
        if(window.scrollY > this.hauteurMobil) {
          this.sticky = true
          this.hauteurMobil = 400
          this.$store.dispatch('isStickyMobil', true)
        } else {
          this.sticky = false
          this.hauteurMobil = 1200
          this.$store.dispatch('isStickyMobil', false)
        }
      } else {
        if(window.scrollY > this.hauteurDesktop) {
          this.sticky = true
          this.hauteurDesktop = 500
        } else {
          this.sticky = false
          this.hauteurDesktop = 800
        }
      }
    },
    updateValue() {
      this.selected = this.$store.state.offer
    },
    itemSelected(offer) {
      this.selected = offer.product;
    },
    // initOffersNavbar(){
    //   var navbar = $(".navbar.offers");
    //   var sticky = navbar.offset().top;
    //   var stickyMobil = navbar.offset().top + 500;
    //   // console.log(stickyMobil)
    //   $(document).scroll(function() {
    //     if(this.widthWindow > 800) {
    //       if (window.pageYOffset > sticky) {
    //         navbar.addClass("sticky-top")
    //       }
    //       if (window.pageYOffset < sticky) {
    //         navbar.removeClass("sticky-top")
    //         sticky = navbar.offset().top;
    //       }
    //     } else {
    //       if (window.pageYOffset > stickyMobil) {
    //         console.log('add sticky mobil')
    //         this.mobilSticky = true
    //         navbar.addClass("sticky-top")
    //       }
    //       if (window.pageYOffset < stickyMobil) {
    //         // console.log('remove sticky mobil')
    //         // this.mobilSticky = false
    //         navbar.removeClass("sticky-top")
    //         stickyMobil = navbar.offset().top;
    //       }
    //     }
    //   })
    // },
    getPdfLink(pdfName){
      return this.garanties.garanties[this.jobName]['pdf_ipid' + pdfName];
    },
    editSimulation(){
      let event = {
        click_name: 'modifier-simulation',
        click_type: 'action',
        composant: 'bouton'
      };
      reloadEvent('click', event)
      this.$router.push({ name: "NeedsCoordonate"})
    }
  },
  computed: mapState([`garanties`, `formTexts`, `cyberProtect`, `price`, `activity`, `needs`, `selectedOffer`, `isMrPro`, `isMobil`]),
}

</script>

<style lang="scss">
#app {
  .table {
    margin-bottom: 0;

    &:not(:last-child) {
      tbody tr td {
        border-bottom: none;
      }
    }
  }

  .accordion-button {
    box-shadow: none;

    &:not(.collapsed) {
      box-shadow: none;
    }
  }
}
</style>
