<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-11 col-lg-8 mt-4">
        <slot name="viewBody"></slot>
      </div>
    </div>
    <slot name="viewFooter"></slot>
  </div>
</template>

<script>
export default {
  name: "ViewContainer"
}
</script>

<style scoped>

</style>
