
export function buildDocumentData(state){
    let textOffer = ''
    let price = ''

    if(state.selectedOffer === 'equilibre') {
        textOffer = "Niveau de protection médian qui vous permet d'optimiser votre budget d'assurance"
    } else if(state.selectedOffer === "essentielle") {
        textOffer = "Niveau de protection inférieur qui vous permet d'optimiser votre budget d'assurance"
    } else if(state.selectedOffer === 'serenite') {
        textOffer = "Niveau de protection supérieur qui vous permet d'optimiser votre budget d'assurance"
    }

    if(state.cyberProtect) {
        price = state.price[state.selectedOffer].price + 16.35
    } else {
        price = state.price[state.selectedOffer].price
    }

    let nameDipa = '';
    let nameDab = '';
    let nameRc = '';
    let nameAssitance = true;

    const isActivityMrPro = state.activity.isMrPro;

    switch (state.activity.jobFamily) {
        case 'hcr':
            nameDipa += 'MR-PRO-hotels-cafes-restaurants';
            nameDab += 'Hotels-cafes-restaurants';
            nameRc += 'Hotels-cafes-restaurants';
            break;
        case 'commerce':
            nameDipa += 'MR-PRO-commerces-non-alimentaires';
            nameDab += 'Commerces-non-alimentaires';
            nameRc += 'commerces-non-ali';
            break;
        case 'comm_ali':
            nameDipa += 'MR-PRO-commerces-alimentaires';
            nameDab += 'Commerces-alimentaires';
            nameRc += 'commerces';
            break;
        case 'fleuriste':
            nameDipa += 'MR-PRO-fleuristes';
            nameDab += 'Fleuristes';
            nameRc += 'fleuristes';
            break;
        case 'sb':
            nameDipa += 'MR-PRO-soins-et-beaute';
            nameDab += 'Soins-et-beaute';
            nameRc += 'soins-et-beaute';
            break;
        case 'sb_domicile':
            nameDipa += 'RC-soins-et-beaute-à-domicile';
            nameRc += 'Soins-et-beautes-a-domicile';
            nameAssitance = false;
            break;
        case 'paramedical':
            nameDipa += 'paramedical';
            isActivityMrPro ? nameDab += 'Paramedical' : '';
            nameRc += 'Paramedical';
            isActivityMrPro ? '' : nameAssitance = false;
            break;
        case 'bien_etre':
            nameDipa += 'bien-etre';
            isActivityMrPro ? nameDab += 'bien-etre' : '';
            nameRc += 'Bien-etre';
            isActivityMrPro ? '' : nameAssitance = false;
            break;
        case 'secu':
            nameDipa += 'sécurité';
            isActivityMrPro ? nameDab += 'securite' : '';
            nameRc += 'Metiers-securite';
            isActivityMrPro ? '' : nameAssitance = false;
            break;
        case 'autre_service':
            nameDipa += 'autres-services';
            isActivityMrPro ? nameDab += 'autres-services' : '';
            nameRc += 'Conseils-et-autres-services';
            isActivityMrPro ? '' : nameAssitance = false;
            break;
    }

    return {
        'civility': state.formPm.civility,
        'name': 'Assurance Multirisque Professionnelle',
        'lastName': state.formPm.lastName,
        'price': price,
        'offer': state.price[state.selectedOffer].title,
        'textOffer': textOffer,
        'cyber': state.cyberProtect,
        'DIPA': nameDipa,
        'DAB': nameDab,
        'RC': nameRc,
        'ASSISTANCE': nameAssitance,
        'email': state.formPm.email,
        'idHmDrive': state.documentsHMDrive,
    }
}