<template>
    <!-- Modal -->
    <div
      class="modal fade"
      :id="id"
      :ref="id"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      :aria-labelledby="id"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" style="max-width: 700px">
        <div class="modal-content pt-3 pb-4">
          <div class="modal-header text-center border-0">
            <p class="modal-title head-modal w-100 fw-bolder " id="staticBackdropLabel">
                Vous êtes sur le point de modifier votre besoin
            </p>
            <button v-if="closable" @click="this.closeModal()" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div v-if="betterOffer">
            <div class="m-5 modal-body body">
              <div class="row">
                <div class="col-md-12">
                  <h6 class="modal-title w-100 fw-bolder text-center">Nous vous avons préconisé la formule « <strong>{{ this.offer[adviceOffer] }}</strong> »</h6>
                </div>
              </div>
            </div>
            <div class="m-5 modal-body body">
              <div class="row">
                <div class="col-md-12">
                  <p class="mainText mb-0 text-center">Au terme de votre prise de connaissance des garanties proposées, vous avez souhaité choisir la formule « <strong>{{ this.offer[selectedOffer] }}</strong> », encore plus prudente et qui offre des franchises plus basses comme des conditions d’indemnisations plus favorables.</p>
                </div>
              </div>
            </div>
          </div>

          <div v-else>
            <div class="m-5 modal-body body">
              <div class="row">
                <div class="col-md-12">
                  <h6 class="modal-title w-100 fw-bolder text-center">Nous vous avons préconisé la formule « <strong>{{ this.offer[adviceOffer] }}</strong> »</h6>
                </div>
              </div>
            </div>
            <div class="m-5 modal-body body">
              <div class="row">
                <div class="col-md-12">
                  <p class="mainText mb-0 text-center">Cependant vous n’avez pas souhaité suivre notre conseil, et vous avez choisi la formule « <strong>{{ this.offer[selectedOffer] }}</strong> ».</p><br>
                  <p class="mainText mb-0 text-center">Cette formule vous permet de bénéficier de garanties équivalentes à celles de notre préconisation mais avec des franchises plus importantes et un montant maximum d’indemnisation plus limité.</p>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-center modal-footer footer-navigation border-0">
            <button v-if="confirmRetour" @click="this.cancelChange()" type="button" class="btn back-button ms-2" data-bs-dismiss="modal">
              <img :src="require('@/assets/svg/Icon feather-arrow-left.svg')" />
              Annuler
            </button>
            <button
              v-if="confirmAction"
              type="button"
              class="btn btn-info rounded-pill"
              data-bs-dismiss="modal"
              @click="confirm"
            >
              Continuer
              <img :src="require('@/assets/svg/arrow-title-white.svg')" />
            </button>
          </div>
        </div>
      </div>
    </div>
</template>
  
<script>
import { mapState } from "vuex";

const bootstrap = require("bootstrap");

export default {
    name: "ModalChangeOffer",
    props: {
        id: String,
        title: String,
        closable: {
            type: Boolean,
            default: true
        },
        confirmAction: Boolean,
        confirmRetour: {
            type: Boolean,
            default: true
        },
        directShow: {
            type: Boolean,
            default: false
        },
    },
    components: {},
    data: function(){
        return {
            myModal: null,
            offer: {
                'essentielle': 'Essentielle',
                'equilibre': 'Equilibre',
                'serenite': 'Sérénité'
            },
        }
    },
    mounted() {
      if (this.directShow){
        this.showModal()
      }
    },
    methods: {
        showModal() {
          this.myModal = new bootstrap.Modal(document.getElementById(this.id), {
            keyboard: false,
          });
          this.myModal.show();
        },
        confirm() {
          this.$emit("confirmAction", true);
          this.closeModal();
        },
        closeModal() {
          this.$store.dispatch('closeOrOpenModal', {
              modalName: 'ModalChangeOffer',
              action: false
          })
        },
        cancelChange() {
          this.closeModal()
          this.$store.dispatch('cancelChange')
        }
    },
    computed: {
        ...mapState([`formTexts`, `modalChangeOffer`, `selectedOffer`, `previousOffer`, `betterOffer`, `adviceOffer`])
    },
    watch: {
        modalChangeOffer() {
            if(this.modalChangeOffer) {
                this.showModal()
            }
        }
    },
};
</script>

<style scoped></style>
