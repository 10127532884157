<template>
  <div class=" need-protection">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12 mb-5 ">

            <transition name="fade">
              <div>
                <h3 class="pt-4 text-md-center title-page" v-html="formTexts.needs_protection.title"></h3>
              </div>
            </transition>
      </div>

            <QuestionLabel class="col-6 pb-5" :question="formTexts.needs_protection.niveau_couverture"/>


          
            <div class="col-12 col-xl-10">
            <OffersCardSwiper>
              <template v-slot:swipeBody>
                <div class="col-md swiper-slide " v-for="(offer, indexOffer) in offersTexts" :key="offer.product">
                  <OfferCard class="col offer-card" advicedOffer="mpe110" :offer-id="offer.product">
                    <template v-slot:header>
                      <div class="row justify-content-center">
                        <div class="col-12">
                          <h4 class="offer-title text-center pt-4 mb-0">{{ offer.name }}</h4>
                        </div>
                      </div>
                    </template>
                    <template v-slot:body>
                      <div class="row">
                        <div class="col-12 text-center">
                          <img v-for="i in indexOffer+1" :key="i" class="pt-4 d-inline" :src="require('@/assets/svg/Icon feather-plus-1.svg')"/>
                           <div class="pt-4" style="height: 125px;" v-html="this.formTexts.needs_protection[offer.product + '_desc'].text"></div>
                        </div>
                      </div>
                    </template>
                    <template v-slot:footer>
                      <div class="row">
                        <div class="col-11 my-4 mx-auto text-center">
                          <OfferSelectButton :class="{ 'tarif-not-ready': offer.loading }"
                                             :offer="offer.product"
                                             cotisation-structure="cotisationStructure"
                                             :ref="'flexRadioDefaultOffer' + offer.name"
                                             :name="'flexRadioDefaultOffer' + offer.name"
                                             default-choice="mpe110" :selected="adviceOffer"
                                             @update:modelValue="itemSelected(offer)" />
                        </div>
                      </div>
                    </template>
                  </OfferCard>
                </div>
              </template>
            </OffersCardSwiper>
          </div>
          </div>
            </div>
            <div v-if="this.alert" class="form-error form-error-text">Veuillez choisir un niveau de couverture</div>

         
   
    <FooterNavigation @submitStep="submitForm" @previousStep="previousStep"/>
  </div>

</template>

<script>
import { mapState } from "vuex";
import OffersCardSwiper from "../../../node_modules/hm-mpe-widgets/src/lib-components/components/Offers/OffersCardSwiper.vue";
import OfferSelectButton from "../../../node_modules/hm-mpe-widgets/src/lib-components/components/Offers/OfferSelectButton.vue";
import OfferCard from "../../../node_modules/hm-mpe-widgets/src/lib-components/components/Widgets/OfferCard.vue";
import QuestionLabel from "../../../node_modules/hm-mpe-widgets/src/lib-components/components/Widgets/QuestionLabel.vue";
import FooterNavigation from "../../components/Navigation/FooterNavigation.vue";
import wrapper from "../../main";
import { reloadEvent } from '../../services/tagCo';
import templateHelpers from "@/services/templateHelpers";

export default {
  name: "NeedsProtection",
  components: {
    OffersCardSwiper,
    OfferSelectButton,
    OfferCard,
    FooterNavigation,
    QuestionLabel
  },
  data: function () {
    return {
      offer: null,
      alert: null,
    }
  },
  created() {
    this.$store.commit("setNextRoute", "NeedsCoordonate");
    wrapper.setTcVars({
      page_name : 'niveau-protection',
      page_cat1_name : 'recueil-de-besoins',
      page_cat2_name : 'tarif-affine',
    });
    reloadEvent('page');
  },
  methods: {
    ...templateHelpers,

    setSwiper(){
      this.initSwiper(0, 3)
    },
    itemSelected(offer) {
      this.$store.commit('setSelectedOffer', offer.product)
      this.$store.commit('setAdviceOffer', offer.product)
      this.$store.commit('setPreviousOffer', offer.product)
      this.alert = false
    },
    submitForm() {
        this.alert = true
        if(this.selectedOffer) {
          this.nextRoute()
        }
    },
    nextRoute() {
        this.$store.dispatch('updateAvancementNeeds', {
            avancementNumber: 3,
            action: true
        })
        this.$router.push({ name: this.$store.getters.getNextRoute });
    },
    previousStep() {
      this.$router.push({ name: "NeedsCyber"})
    }
  },
  computed: mapState([`formTexts`, `selectedOffer`, `offersTexts`, `adviceOffer`]),
}
</script>
