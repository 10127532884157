// import {getPrice} from "@/services/jsonTest";

export default {
	prospect: null,
	avancement: [],
	avancementNeeds: [],
	formTexts: null,
	loadedTexts : false,
	selectActivities: null,
	nafList: null,
	nextRouteName: '',
	questionSteps: null,
	fastPrice: null,
	needs: null,
	isCourtage: false,
	isActivityCourtage: false,
	rcCourtage: null,
	mrProCourtage: null,
	modalWcb: false,
	modalActivityHC: false,
	modalChangeOffer: false,
	modalSendMail: false,
	garanties: null,
	cyberProtect: null,
	offer: null,
	previousOffer: null,
	selectedOffer: null,
	adviceOffer: null,
	betterOffer: null,
	isMobil: null,
	stickyMobil: null,
	codeAction: null,
	newCompany: null,
	price: {
		essentielle: null,
		equilibre: null,
		serenite: null,
	},
	activity: {
		nbActivities: null,
		ca: null,
		prospectActivity: null,
		product: null,
		productOds: null,
		jobFamily: null,
		previousActivity: null,
		previousCa: null,
		isMrPro: null
	},
	formPm: {
		siret: null,
		waitingSiret: false,
		raisonSociale: '',
		formeJuridique: '',
		nbEmployees: {
			index: null,
			value: null,
		},
		addressSiege: '',
		citySiege: '',
		createdDate: '',

		civility: '',
		firstName: '',
		lastName: '',
		phoneNumber: '',
		hasPhone : false,
		email: '',
		confirmEmail: '',
		checkEmail: false,
		postalCode: '',
		city: '',

		postalCodeAndCity: {
			city: null,
			zipCode: null,
		},
	},
	local: {
		content: null,
		cities: null,
		nbBuildings: null,
		statusOwner: null,
		addressLocal: null,
		postalCode: null,
		cityLocal: null,
		statutOccupant: null,
		superficie: null,
		nbSinistres: null,
		degatsEaux: null,
		codeInsee: null,
		postalCodeAndCity: {
			city: null,
			zipCode: null,
		},
	},
	contact: {
		idProcess: null,
	},
	uuid: null,
	optins: null,
	isConsentementAllRight: null,
	offersTexts : [
		{
			name: "Essentielle",
			product: "essentielle",
		},
		{
			name: "Equilibre",
			product: "equilibre",
		},
		{
			name: "Sérénité",
			product: "serenite",
		},
	],
	callBack: {
        odigoToken: '',
        phone: '',
        zipCode: '',
        slot: '',
        date: '',
        successOdigo: false,
        failOdigo: false,
        closableOdigo: true,
		titleWcb: "Contacter un conseiller Harmonie Mutuelle"
    },
}
