<template>
  <div class="doc-panel" @click="downloadPdf()">
    <div class="d-inline-block doc-icon">
      <img :src="require('@/assets/svg/file_icon.svg')" />
    </div>
    <div class="d-inline-block">
      <span class="doc-title">{{ docTitle }}</span>
    </div>
    <div class="d-inline-block float-end">
      <img :src="require('@/assets/svg/download_btn.svg')" />
    </div>
  </div>
</template>

<script>
export default {
  name: "DocPanel",
  props: {
    docTitle : null,
    pdfLink : null
  },
   methods: {
    downloadPdf(){
      window.open(this.pdfLink, '_blank').focus();
    }
   }
}
</script>

<style scoped>

</style>
