<template>

    <div
    class="modal modal_contact fade"
    :id="id"
    :ref="id"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    :aria-labelledby="id"
    aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered" style="max-width: 700px">
            <div class="modal-content pt-3 pb-4">
                <div class="modal-header text-center border-0">
                    <button
                        v-if="callBack.closableOdigo"
                        type="button d-flex justify-content-end"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        @click="closeModal()"
                    ></button>
                </div>

                <CallBack/>

            </div>
        </div>
    </div>

</template>

<script>
import {mapState} from "vuex";
import CallBack from './CallBack.vue';
import wrapper from '@/main';
import { reloadEvent } from '@/services/tagCo';

const bootstrap = require("bootstrap");

export default {
    name: "ModalCallBack",
    props: {
        id: String,
    },
    components: { CallBack },
    data: function () {
        return {
            myModal: null,
        }
    },
    methods: {
        showModal() {
            wrapper.setTcVars({
                page_name : 'rappel',
                page_cat1_name : 'entrepreneurs',
                page_cat2_name : '',
            });
            reloadEvent('page');

            this.myModal = new bootstrap.Modal(document.getElementById(this.id), {
                keyboard: false,
            });
            this.myModal.show();
        },
        closeModal(){
            this.$store.dispatch('closeOrOpenModal', {
                modalName: 'ModalWcb',
                action: false
            })
            this.$store.dispatch('resetInfoOdigo')
        }
    },
    computed: mapState([`modalWcb`, `callBack`]),
    watch: {
        modalWcb() {
            if(this.modalWcb) {
                this.showModal()
            }
        }
    },
};
</script>
  
  