
export function getInterfactionTarif(state){
    let interlocuteur = ''

    interlocuteur += 'Interlocuteur : ' + state.formPm.civility + ' ' + state.formPm.lastName + ' ' + state.formPm.firstName + '\n'
    interlocuteur += 'Téléphone : ' + state.formPm.phoneNumber + '\n'
    interlocuteur += 'Email : ' + state.formPm.email + '\n'
    interlocuteur += 'Intéressé par : ' + state.selectedOffer + '\n\n'

    let entreprise = ''
    let local = ''
    let statut = ''

    local += 'Nombre de local: ' + state.local.nbBuildings + '\n'
    local += 'Superficie : ' + state.local.superficie + '\n'
    local += 'Valeur de contenu par local : ' + state.local.content + '\n'

    if(state.local.statusOwner === 'autre'){
        if(state.local.statutOccupant === 'free_occupant'){
            statut = 'Occupant à titre gratuit'
        } else if(state.local.statutOccupant === 'subtenant'){
            statut = 'Sous locataire'
        } else {
            statut = 'Locataire agissant pour le compte du propriétaire'
        }
    } else if(state.local.statusOwner === 'tenant'){
        statut = 'Locataire'
    } else {
        statut = 'Propriétaire'
    }

    if(!state.activity.isMrPro){
        statut = "non renseigné"
    }

    local += 'Statut occupant : ' + statut + '\n'
    local += 'Adresse : ' + state.local.addressLocal + ' ' + state.local.postalCodeAndCity.zipCode + ' ' + state.local.postalCodeAndCity.city + '\n'
    local += 'Nombre sinistre : ' + state.local.nbSinistres + '\n'

    entreprise += state.formPm.createdDate != '' ?  'Date de création : ' + state.formPm.createdDate + '\n' : '\n'
    entreprise += "Chiffre d'affaire : " + state.activity.ca + '\n'
    entreprise += state.cyberProtect ? "Dispose d'un site internet et/ou d'une base de données : OUI \n" : "Dispose d'un site internet et/ou d'une base de données : NON \n"
    entreprise += local

    let observation = '';
    observation += state.activity.isMrPro ? 'MR PRO' : 'RC sèche'
    observation += state.cyberProtect ? ' + cyber \n' : '\n'
    observation += interlocuteur
    observation += entreprise

    return {
        "codeAction": state.codeAction ? state.codeAction : 'WEB_PRO_SEO_NA',
        "canal":"Web",
        "evtDeclencheur": "Vu sur internet",
        "objet": "Affichage tarif",
        "garantie": "MR PRO",
        "observation": observation
    };
}

export function getDevisInteraction(state){
    return getInteraction("Devis envoyé", state)
}

export function getSouscriptionInteraction(state){
    return getInteraction("Initialisation souscription", state)
}

export function getInfoProInteraction(state){
    return getInteraction("Informations pro complétées", state)
}

export function getRIAInteraction(state){
    return getInteraction( "Décrochage RIA", state)
}

export function getSignatureInteraction(state){
    return getInteraction("Attente Signature électronique", state)
}

export function getValidationInteraction(state){
    return getInteraction("Souscription enregistrée", state)
}

function getInteraction(objetInterraction, state){
    return {
        "objet": objetInterraction,
        "codeAction": state.contact.codeAction ? state.contact.codeAction : 'code_action',
        "evtDeclencheur": "Vu sur internet",
    }
}
