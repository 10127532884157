
export function buildStudyData(state){
    let siret = ''
    if(state.formPm.waitingSiret) {
        siret = '00000000000000'
    } else {
        siret = state.formPm.siret
    }

    let bpLibelleStructure = ''
    if(state.activity.isMrPro) {
        bpLibelleStructure = "MRPRO VYV"
    } else  {
        bpLibelleStructure = "Responsabilité civile VYV"
    }

    let produit
    if(state.cyberProtect) {
        produit = [
            {
                "bpLibelle": "Devis",
                "structureCotisations": [
                    {
                        "bpCodeStructureCotisation": "",
                        "bpLibelleStructureCotisation": "",
                        "bpLibelleStructure": bpLibelleStructure,
                        "bpTarifStructureCotisation": state.price[state.selectedOffer].price
                    }
                ]
            },
            {
                "bpLibelle": "",
                "structureCotisations": [
                    {
                        "bpCodeStructureCotisation": "",
                        "bpLibelleStructureCotisation": "",
                        "bpLibelleStructure": "Option Cyber",
                        "bpTarifStructureCotisation": 16.35
                    }
                ]
            },
            {
                "bpLibelle": " ",
                "structureCotisations": [
                    {
                        "bpCodeStructureCotisation": "",
                        "bpLibelleStructureCotisation": "",
                        "bpLibelleStructure": "Total",
                        "bpTarifStructureCotisation": state.price[state.selectedOffer].price + 16.35
                    }
                ]
            }
        ]
    } else {
        produit = [
            {
                "bpLibelle": "Devis",
                "structureCotisations": [
                    {
                        "bpCodeStructureCotisation": "",
                        "bpLibelleStructureCotisation": "",
                        "bpLibelleStructure": bpLibelleStructure,
                        "bpTarifStructureCotisation": state.price[state.selectedOffer].price
                    }
                ]
            }
        ]
    }

    return {
        "prospect": {
            "siretPM": siret,
            "adresse": state.local.addressLocal,
            "raisonSociale": state.formPm.raisonSociale,
            "codePostal": state.prospect.postal_code,
            "ville": state.prospect.city,
            "civiliteInterlocuteur": state.formPm.civility,
            "emailInterlocuteur": state.formPm.email,
            "nomInterlocuteur": state.formPm.lastName,
            "prenomInterlocuteur": state.formPm.firstName,
            "telephoneInterlocuteur": state.formPm.phoneNumber
        },
        "tarification": {
            "dateEffet": "2000-01-01",
            "type": "PersonneMorale",
            "dossier": {
                "type": "SANTE"
            },
            "offre": {
                "bpCode": "MRPRO",
                "regimes": [
                    {
                        "produits": produit
                    }
                ]
            }
        },
        "idProcess": state.contact.idProcess
    }
}