<template>
  <ViewContainer>
    <template v-slot:viewBody>
      <div class="row mb-5">
        <div class="col-12">
          <div>

            <h3 class="text-md-center title-page" v-html="formTexts.fast_price_activity.title"></h3>

            <div class="row">
              <div class="col-10 col-lg-12 mt-4">
                <div class="row mb-5">
                  <div class="col-12">
                    <RadioInput
                        :inputColSize="0"
                        :submitted="submit"
                        :question="formTexts.fast_price_activity.nb_activities"
                        v-model="activity.nbActivities"
                        :default-choice="activity.nbActivities"
                        @update:modelValue="checkNbActivity"
                        v-bind:class="{ columnDirection: true }"
                    ></RadioInput>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="activity.nbActivities === 'one_activity'">
              <div class="row">
                <div id="nafcode" class="not-ready col-12 col-md-12 mb-3" onclick="selectSearch()">
                  <CodeNaf
                      :componentTexts="formTexts.fast_price_activity.activities"
                      :codeNafList="this.$store.state.selectActivities"
                      :displayColumn="true"
                      :default-value="activity.prospectActivity"
                      @update:modelValue="itemSelected"
                      @click="selectSearch()">
                  </CodeNaf>
                  <p class="activityNotFound" @click="activityNotFound()">Je n'ai pas trouvé mon activité</p>
                </div>
              </div>
            </div>
            <div v-if="activity.nbActivities === 'one_activity' && activity.prospectActivity && !activity.prospectActivity.includes('HORS CIBLE')">
              <div class="row">
                <div class="col-12 col-md-12 mb-3">
                  <div class="input-group">
                    <TextInput class="justify-content-center form-control p-0 border-0 d-flex"
                               v-model="this.ca"
                               :submitted="submit"
                               :valid-input="validValue"
                               :valid-format="true"
                               :default-value="this.ca"
                               :question="formTexts.fast_price_activity.ca"
                               @update:textInput="checkCa"
                               :display-column="true"
                               inputName="ca"
                               type="number"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:viewFooter>
      <FooterNavigation @submitStep="submitForm"/>
      <Encart :componentTexts="formTexts.fast_price_activity.blocs_texte" ></Encart>
    </template>
  </ViewContainer>
  <ModalHC id="modalActivityHC" ref="modalActivityHC"/>

</template>

<script>
import { mapState } from "vuex";
import CodeNaf from "../../../node_modules/hm-mpe-widgets/src/lib-components/components/FormPmPp/CodeNaf.vue"
import RadioInput from "../../../node_modules/hm-mpe-widgets/src/lib-components/components/Widgets/RadioInput.vue";
import ModalHC from "../../components/Navigation/ModalHC.vue";
import FooterNavigation from "../../components/Navigation/FooterNavigation.vue";
import {getFamilyFromNaf} from "@/services/jobFamilyService";
import TextInput from "../../../node_modules/hm-mpe-widgets/src/lib-components/components/Widgets/TextInput.vue";
import ViewContainer from "@/components/Navigation/ViewContainer";
import Encart from "../../../node_modules/hm-mpe-widgets/src/lib-components/components/Encart.vue";
import wrapper from "../../main";
import { reloadEvent } from '../../services/tagCo';

export default {
  name: "FastPriceActivity",
  components: {ViewContainer, CodeNaf, RadioInput, FooterNavigation, ModalHC, TextInput, Encart },
  data: function() {
    return {
      nbActivities: null,
      ca: null,
      submit: false,
      validValue: false,
    }
  },
  created() {
    if(this.activity.prospectActivity) {
      this.activity.previousActivity = this.activity.prospectActivity
    }
    if(this.$store.state.activity.ca) {
      this.activity.previousCa = this.activity.ca
    }
    if(this.activity.ca) {
      this.ca = this.activity.ca
      this.validValue = true
    }
    this.$store.dispatch("getActivities");

    wrapper.setTcVars({
      page_name : 'activite',
      page_cat1_name : 'recueil-de-besoin',
      page_cat2_name : 'tarif-rapide',
    });
    reloadEvent('page');
  },
  methods: {
    itemSelected(activity) {
      this.activity.prospectActivity = activity;
      // this.submit = false
      //this.nbActivities =  !this.prospectActivity.includes('HORS CIBLE');
      // this.$store.dispatch("checkActivityCourtage")
    },
    updateValue() {
      this.prospectActivity = this.$store.state.activity.prospectActivity
      this.ca = this.$store.state.activity.ca
      this.nbActivities = this.$store.state.activity.nbActivities
    },
    submitForm() {
      this.submit = true
      if(this.activity.prospectActivity !== null && this.activity.prospectActivity.includes("HORS CIBLE")) {
        this.$store.dispatch('closeOrOpenModal', {
        modalName: 'ModalActivityHC',
        action: true
        });
        wrapper.setTcVars({
            page_name : 'activites-hors-cible',
            page_cat1_name : 'exclusion',
            page_cat2_name : '',
          });
          reloadEvent('page');
      }
      if(this.activity.nbActivities === "many_activities" || parseInt(this.ca) > 1500000) {
        this.$store.dispatch('closeOrOpenModal', {
          modalName: 'ModalWcb',
          action: true
        })
        if(this.activity.nbActivities === "many_activities") {
          wrapper.setTcVars({
            page_name : 'plusieurs-activites',
            page_cat1_name : 'exclusion',
            page_cat2_name : '',
          });
          reloadEvent('page');
        } else {
          wrapper.setTcVars({
            page_name : 'exclusion-CA',
            page_cat1_name : 'exclusion',
            page_cat2_name : '',
          });
          reloadEvent('page');
        }
      }
      if (this.activity.nbActivities && this.activity.prospectActivity && this.ca && this.activity.nbActivities !== 'many_activities' && !this.activity.prospectActivity.includes("HORS CIBLE") && parseInt(this.ca) < 1500001 && this.validValue) {
        this.activity.ca = this.ca
        this.$store.commit("setNextRoute", "FastPriceLocal");
        this.nextRoute();
      }
    },
    activityNotFound(){
      this.$store.dispatch('closeOrOpenModal', {
          modalName: 'ModalWcb',
          action: true
        })
      wrapper.setTcVars({
        page_name : 'activites-non-listee',
        page_cat1_name : 'exclusion',
        page_cat2_name : '',
      });
      reloadEvent('page');
    },
    checkCa(value) {
      this.submit = false
      this.ca = value
      if(!isNaN(parseInt(this.ca)) && parseInt(this.ca) > 0) {
        this.validValue = true
      } else {
        this.validValue = false
      }
    },
    nextRoute() {
      //this.$store.state.avancement[1]=true;
      this.$store.dispatch('updateAvancement', {
            avancementNumber: 1,
            action: true
        })
      this.activity.jobFamily = getFamilyFromNaf(this.activity.prospectActivity);
      this.$store.dispatch('resetPrice')
      this.$router.push({ name: this.$store.getters.getNextRoute });
    },
    checkNbActivity() {
      this.submit = false
    },
    checkActivity(){
      this.submit = false
    },
    borderClass() {
      return "greenBorder"
    },
    selectSearch() {
      const input = document.getElementsByClassName('select2-search__field')[0];
      input.focus();
    }
  },
  watch: {
      selectActivities : function() {
        if(this.$store.state.selectActivities != null && this.activity.nbActivities === 'one_activity'){
          document.getElementById("nafcode").classList.remove("not-ready");
        }
      }

  },
  computed: mapState([`formTexts`, `activity`, `selectActivities`]),
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

.select2 {

  width: 100% !important;
  .select2-selection {
    background-color: #fff;
    border-radius: 20px;
    height: 44px;
    outline: none !important;
    border-color: #d9d9d9;
    border-width: 2px;
    box-shadow: none;
    .select2-selection__rendered {
      color: #444;
      line-height: 24px;
      padding-left: 1.5rem;
      padding-top: 0.5rem;
    }
    .select2-selection__arrow {
      top: 10px;
      right: 10px;
    }

    &.select2-selection--single[aria-disabled="true"]{
      background: #e9ecef;
    }
  }
}

.greenBorder{
  border: 2px solid #09b459;
}

.redBorder{
  border: 2px solid #BE0000;
}

.activityNotFound {
  text-decoration-line: underline;

  opacity: 0.7;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

</style>
