import axios from "axios";
import {isProduction} from "@/services/WizardHelperService";

export default axios.create({
	baseURL: isProduction() ? `https://api.harmonie-mutuelle.plussimple.fr/v2/` : `https://api.staging.harmonie-mutuelle.plussimple.io/v2/`,
	headers: {
		"Accept": "application/json",
		"Content-Type": "application/json",
		'Authorization': isProduction() ? 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYXBpLmhhcm1vbmllLW11dHVlbGxlLnBsdXNzaW1wbGUuZnIiLCJpYXQiOjE2MzUxNjY2ODUsIm5iZiI6MTYzNTE2NjY4NSwianRpIjoid3lYcU0xT2hmMmI4TjVQeCIsInN1YiI6InZ5diIsInBydiI6IjBiZjZjNzFmN2MzOWI4MWEyYzFiNzUxNjBjOWRhMmU1N2MyZmVkNjYifQ.WFNig5bmPNh3UMtw6tNSDbtbsHaLa7x1wzwXf35F-I8' : 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYXBpLnN0YWdpbmcuaGFybW9uaWUtbXV0dWVsbGUucGx1c3NpbXBsZS5pbyIsImlhdCI6MTYzNTE2NjYxNywibmJmIjoxNjM1MTY2NjE3LCJqdGkiOiJ2N0ZyMklKMXVkaEJ6UGdDIiwic3ViIjoidnl2dnl2IiwicHJ2IjoiMGJmNmM3MWY3YzM5YjgxYTJjMWI3NTE2MGM5ZGEyZTU3YzJmZWQ2NiJ9.W1zxwGRikJSNqtNxdmVBuuIEfKlXehZyVbAZuD352e0',
	}
})

export function getUpdateStateData(state){
	return {
		'name' : state.formPm.lastName,
		'firstname' : state.formPm.firstName,
		'parcours_type' : 'mr-pro',
		'saved_data': state
	};
}
