<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div
            v-if="this.$route.path.includes('souscription')"
            id="nav"
            class="row justify-content-center mt-4 mb-4">
        </div>

        <div
            v-else
            id="nav"
            class="row justify-content-center mt-4">
          <div class="col-lg-12">
            <div class="row justify-content-center gx-0 gx-md-2">
              <NavLink v-if="this.$store.state.local.nbBuildings !== '00'"
                :step-index="this.index[0]"
                :complet="this.$store.state.avancementNeeds[1]"
                route-name="NeedsLocal"
                :link-text="formTexts.recueil_besoin.besoin_local.text"
                linkColSize="col-md-2"
              />

              <NavLink
                :step-index="this.index[1]"
                :complet="this.$store.state.avancementNeeds[2]"
                route-name="NeedsCyber"
                :link-text="formTexts.recueil_besoin.besoin_cyper.text"
                linkColSize="col-md-2"
              />

              <NavLink
                :step-index="this.index[2]"
                :complet="this.$store.state.avancementNeeds[3]"
                route-name="NeedsProtection"
                :link-text="formTexts.recueil_besoin.besoin_protection.text"
                linkColSize="col-md-2"
              />

              <NavLink
                :step-index="this.index[3]"
                :complet="this.$store.state.avancementNeeds[4]"
                route-name="NeedsCoordonate"
                :link-text="formTexts.recueil_besoin.besoin_coordonnees.text"
                linkColSize="col-md-2"
              />

              <NavLink
                :step-index="this.index[4]"
                :complet="this.$store.state.avancementNeeds[5]"
                route-name="NeedsPrice"
                :link-text="formTexts.recueil_besoin.besoin_tarif.text"
                linkColSize="col-md-2"
              />

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <router-view/>
</template>

<script>
import { mapState } from "vuex";
import NavLink from "@/components/Navigation/NavLink";

export default {
  name: "Needs",
  components: { NavLink },
  data: function () {
    return {
      linkTexts: Object,
      completed: null,
      completedSouscription: null,
      index: null,
    }
  },
  created() {
    this.completed=this.$store.state.avancementNeeds;
    if(this.$store.state.local.nbBuildings === "00") {
      this.index = ["1", "1", "2", "3", "4"]
    } else {
      this.index = ["1", "2", "3", "4", "5"]
    }
    // if ( this.contact.idProcess === null && ['Situation', 'VotreSituation', 'InformationsPro', 'Besoins', 'Prevoyance', 'Prevoyance', 'CouvertureSante1', 'CouvertureSante2', 'InfosPersos', 'InformationsPersonnelles', 'InformationsPersonnellesComplete', 'Devis' ].includes(this.$route.name)){
    //   this.$router.push({ name: 'Situation' });
    // }
  },
  computed: mapState([`formTexts`, `contact`]),
}
</script>

<style scoped>

</style>
