<template>
  <Loading :active="!this.loadedTexts"
           :is-full-page="true"/>

  <div v-if="this.loadedTexts">
    <Wizard/>

    <FooterIcons v-if="this.$router.currentRoute.value.name !== 'NeedsPrice'"/>
    <Footer
        :linkText="formTexts.footer.titre_lien.text"
        :link="formTexts.footer.lien.text"
        :collapseInputText= "formTexts.footer.titre_collapse.text"
        :collapsedText="formTexts.footer.contenu.text"
    ></Footer>
    <div v-show="this.$router.currentRoute.value.name == 'NeedsPrice'" style="padding-top: 5em"></div>
  </div>
</template>

<script>
import Wizard from "@/components/Wizard";
import Footer from "../node_modules/hm-mpe-widgets/src/lib-components/components/Widgets/Footer.vue"
import {mapState} from "vuex";
import Loading from 'vue-loading-overlay';
import FooterIcons from "@/components/Navigation/FooterIcons";

export default {
  name: "Activities",
  components: {Wizard, Loading, Footer, FooterIcons},
  created(){
    this.saveCodeAction();
    this.$store.dispatch("getFormTexts");
  },
  methods: {
    saveCodeAction(){
      const urlParams = new URLSearchParams(window.location.search);
      this.$store.commit('setCodeAction', urlParams.has('ca') ? urlParams.get('ca') : "WEB_PRO_SEO_NA")
    }
  },
  computed: mapState([`loadedTexts`, `formTexts`]),
}
</script>

<style lang="scss">
@import "~bootstrap";
@import "assets/scss/app.scss";
@import "~hm-mpe-widgets/src/lib-components/assets/scss/components/app.scss";
@import "~hm-mpe-widgets/src/lib-components/assets/scss/vendors/bootstrap-vue/_upload_icon.scss";
</style>
