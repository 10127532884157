<template>
  <div class="row justify-content-center align-items-center">
    <div :class="[sendDevisClass]">
      <div class="devis-link-container text-center float-md-start">
        <a class="send-devis-link" @click="this.recevoirDevis()">
          Recevoir mon devis par mail <img :src="require('@/assets/svg/enveloppe.svg')" /></a>
      </div>
    </div>

    <a class="text-white souscrire-btn btn btn-info2 justify-content-center"
       :class="[inNav ? '' : 'devis-buttons-text p-3 px-4 btn-souscrire', subscribeClass]"
       @click="goToSouscription()">Souscrire à cette offre <img :src="require('@/assets/svg/arrow-title-white.svg')" /></a>
  </div>

</template>

<script>
import {mapState} from "vuex";
import {buildStudyData} from '@/services/etudeAPI/etudeCallBuilder';
import {buildDocumentData} from '@/services/documentCallBuilder';
import wrapper from "../../main";
import { reloadEvent } from "../../services/tagCo";

export default {
  name: "DevisButton",
  props: {
    inNav: {
      type: Boolean,
      default: false,
    },
    sendDevisClass : {
      type : String,
      default : ''
    },
    subscribeClass : {
      type : String,
      default : 'col-12 col-md-6'
    }
  },
  data: function(){
    return {
      sendedModalText: null,
      api: null,
    }
  },
  created() {
    this.api = true //this.isApiContactAllRight;
  },
  methods: {
    recevoirDevis(){
      wrapper.setTcVars({
        page_name : 'confirmation-envoi-devis',
        page_cat1_name : 'recueil-de-besoins',
        page_cat2_name : 'tarif-affine',
      });
      reloadEvent('page');

      let event = {
        click_name: 'recevoir-par-mail',
        click_type: 'action',
        composant: 'bouton'
      };
      reloadEvent('click', event)

      this.sendMail();

      this.$store.dispatch('closeOrOpenModal', {
        modalName: 'ModalSendMail',
        action: true
      })
      let data = {
        "interaction": {
          "objet": "Devis envoyé",
        },
        "opportuniteRelationnelle": {
          "objet": "Devis envoyé",
          "priorite":"85",
        },
      }
      this.$store.dispatch('updatePm', data);
    },
    goToSouscription(){
      this.sendMail();
      let event = {
        click_name: 'Souscrire',
        click_type: 'action',
        composant: 'bouton'
      };
      reloadEvent('click', event)
      window.open("https://rappel.harmonie-mutuelle.fr/entrepreneur", '_blank').focus();
    },
    sendMail(){
      let dataEtude = buildStudyData(this.$store.state)
      this.$store.dispatch('createStudy', dataEtude).then(() => {
        let dataDocument = buildDocumentData(this.$store.state)
        this.$store.dispatch('sendMail', dataDocument)
      })
    }
  },
  computed: {...mapState([`isApiContactAllRight`,`formPmPp`, `formTexts`, `tarification`, `uuid`, `selectedOfferSante`, `selectedOfferPrevoyance`, `cotisationSelected`, `contact`])},
}
</script>
