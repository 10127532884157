<template>
  <div class="hello">
    <h1>{{ msg }}</h1>

    <h2>Prospect Id : {{prospectId}}</h2>
    <p>
      For a guide and recipes on how to configure / customize this project,<br />
      check out the
      <a href="https://cli.vuejs.org" target="_blank" rel="noopener"
      >vue-cli documentation</a
      >.
    </p>

    <a class="btn btn-outline-info" @click="answerAll">Answer all</a>
    <a class="btn btn-outline-info" @click="getPrice">Get Price</a>
    <a class="btn btn-outline-info" @click="getActivities">Get Activities</a>
    <a class="btn btn-outline-info" @click="getReco">Get recommandations</a>


    <p v-if="price !== null">Prix à partir de : {{ price["vyv-iard-container"].choices.economique.debug.price }} </p>
    <p v-if="codeProduitCommercial !== null">Code Produit Commercial : {{ codeProduitCommercial["produitsCommerciaux"][0]["codeProduitCommercial"] }} </p>

    <ul>
      <li v-for="question in this.questionsText" :key="question.id">
        <SelectInput
            :default-choice="responses[question.id]"
            :valid-input="validName"
            :submitted="submit"
            input-name="status"
            :question="question"
            :ref="'questionInput-'+question.id"
            @update:modelValue="itemSelected">
        </SelectInput>

        <!--        <RadioInput v-if="question.type === 'radio'"-->
        <!--            :submitted="submit"-->
        <!--            :question="question"-->
        <!--            :default-choice="this.inputValue"-->
        <!--            :ref="'questionInput-'+question.id"-->
        <!--            :name="question.id"-->
        <!--            @update:modelValue="itemSelected"-->
        <!--        ></RadioInput>-->
      </li>
    </ul>
  </div>
</template>

<script>
import api from "@/services/api";
import ods from "@/services/ods";
import SelectInput from "@/components/Widgets/SelectInput";

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  components: {SelectInput},
  data : function(){
    return {
      prospectId : 'f87504ba-87e4-4d88-9106-c30fa8f14aa4',
      questions : [],
      validName: false,
      submit: false,
      inputValue: null,
      inputName: null,
      questionsText : [],
      responses : [],
      simpleQuestions : [],
      questionSteps: [],
      price: null,
      codeProduitCommercial: null
    }
  },
  methods: {
    createProspect() {
      return api.post('prospects', {
        "email": "agabriel@plussimple.com",
        "phone": "+33617323273",
        "owner_status": "M",
        "owner_firstname": "Adrien",
        "owner_lastname": "GABRIEL"
      }).then((r) => {
        this.$store.state.prospect = r.data.data;
        this.prospectId = this.$store.state.prospect.id
      })
    },
    validateProspect() {
      let prospectId = this.$store.state.prospect.id
      api.get(`/prospects/${prospectId}/validate`).then((r) => {
        console.log(r.data.data);
      })
    },
    addProduct() {
      return api.post(`prospects/${this.$store.state.prospect.id}/products`, {"slug": "vyv-iard-container"}).then(() => {
        console.log('ok');
      })
    },
    getPrice(){
      api.get(`/prospects/${this.prospectId}/offers`).then((r)=> {
        this.price = r.data.data
      })
    },

    getAllSteps() {
      return api.get(`/prospects/${this.prospectId}/questions-steps`).then((r) => {
        this.questionSteps = r.data.data["vyv-iard-container"]
      })
    },
    getSteps() {
      api.get(`/prospects/${this.prospectId}/questions-steps/${this.getNextStep()}`).then((r) => {
        let that = this
        this.simpleQuestions = r.data.data.questions
        r.data.data.questions.map(function (value) {
          let choices = []

          let crtQuestionType = 'select'
          value.values.map(function (questionValue) {
            let choice = {}
            if (questionValue.value === true || questionValue.value === false) {
              crtQuestionType = 'radio'
            }
            choice[questionValue.value] = questionValue.label
            choices.push(choice)
          })
          that.questionsText.push({
            id: value.id,
            text: value.label,
            choices: choices,
            type: crtQuestionType
          })

          that.responses[value.id] = ''
        });
      })
    },

    answerAll() {
      // createProspect ->
      // addProduct ->
      // {{ initProduct(criterias)
      // -> getQuestionsSteps
      // -> getStep
      // -> updateProduct(criterias) } faire jusqu'à ce que getQuestionsSteps.next_step = null } -> getOffers
      this.createProspect().then(() => {
        this.addProduct().then(() => {
          let data = {
            "vyv-iard-container": {
              "criterias": [
                {
                  "key": "needs",
                  "value": "mrp"
                },
                {
                  "key": "turnover",
                  "value": 15000
                },
                {
                  "key": "activities",
                  "value": [
                    {
                      "code": "9602A1",
                      "turnover_part": 100
                    }
                  ]
                },
                {
                  "key": "has_creator_decrease",
                  "value": true
                },
              ]
            }
          };
          return api.put(`https://api.demo.harmoniemutuelle.plussimple.io/v2/prospects/${this.prospectId}/criterias`, data).then((r) => {
            console.log(r)
            this.recursiveSteps()
          })
        })
      })
    },

    recursiveSteps() {
      if (this.questionSteps["next_step"] !== null) {
        let that = this;

        this.getAllSteps().then(() => {
          api.get(`/prospects/${that.prospectId}/questions-steps/${that.questionSteps.next_step}`).then((r) => {
            console.log(r.data.data)
            let responses = []
            if ("vyv-iard-container-sites-form" === that.questionSteps.next_step) {
              responses = this.getBuildingAnswer();
            }else{

              r.data.data.questions.map(function (q) {
                let value = q.values[0].value
                if ("vyv-iard-container-number-of-sites-form" === that.questionSteps.next_step){
                  value = 1
                }
                responses.push({
                  "key": q.id,
                  "value": value,
                })
              })
            }

            let dataToSend = {
              "vyv-iard-container": {
                "criterias": responses,
                "step": that.questionSteps.next_step
              }
            };

            api.put(`prospects/${that.prospectId}/criterias`, dataToSend).then(() => {
              this.recursiveSteps();
            })
          });
        })
      }else{
        console.log('ok tout bon')
      }
    },

    submitForm(){
      let dataToSend = {
        "vyv-iard-container": {
          "criterias": this.responses,
          "step": this.getNextStep()
        }
      };

      api.put(`prospects/${this.prospectId}/criterias`, dataToSend).then((r) => {
        console.log(r.data)
      })
    },

    itemSelected(item) {
      let crtResponseIndex = this.responses.findIndex((r) => r.key === item.id)
      let criteria = {
        key : item.id,
        value : `${item.value}`
      }
      if (crtResponseIndex >= 0){
        this.responses[crtResponseIndex] = criteria
      }else{
        this.responses.push(criteria)
      }
    },

    getKillingQuestions(){
      api.get(`prospects/${this.prospectId}/killing-questions`).then((r) => {
        console.log(r.data.data)
      })
    },

    getNextStep(){
      let nextStep = this.questionSteps["next_step"]
      if (this.questionSteps["current_step"] !== null){
        nextStep = this.questionSteps["current_step"]
      }
      return nextStep
    },

    getBuildingAnswer(){
      return  [
        {
          "key": "buildings",
          "value": [
            {
              "address": "1 rue Saint Dizier",
              "city": "Nancy",
              "insee_code_city": "54395",
              "postal_code": "54000",
              "surface": 100,
              "content": 30000,
              "occupant_status": "subtenant"
            }
          ]
        }
      ]
    },

    getReco() {
      let dataToSend = {
        "nafDetails": [
          {
            "codeNafDetail": "8810B1"
          }
        ],
        "chiffreAffaires": 10,
        "garantiesMrproRc": "MRPRO"
      }
      ods.post(`addagio/v2/recommandations`, dataToSend).then((r) => {
        console.log(r.data)
        this.codeProduitCommercial = r.data
      })
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~bootstrap";
</style>
