<template>
  <ViewContainer>
    <template v-slot:viewBody>
      <div class="row mb-5">
        <div class="col-12">
            <div>
              <h3 class="text-md-center title-page" v-html="formTexts.needs_local.title"></h3>

              <Address
                  :default-value="this.addressLocal"
                  :component-texts="this.addressQuestion"
                  :displayColumn="true"
                  ref="address"
              />

              <div class="row" v-if="this.$store.state.local.statusOwner === 'autre'">
                <div class="col-12 mt-4">
                  <div class="row mb-5">
                    <div class="col-12">
                      <RadioInput
                          :inputColSize="0"
                          :question="formTexts.needs_local.statut_occupant"
                          v-model="statutOccupant"
                          :submitted="submitStatusOccupant"
                          :default-choice="this.statutOccupant"
                          v-bind:class="{ columnDirection: true }"
                      ></RadioInput>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-md-12 mb-3">
                  <div class="input-group">
                    <SelectInput
                        class="justify-content-center form-control p-0 border-0 d-flex"
                        v-model="valueEquipment"
                        :submitted="submitContent"
                        :valid-input="validValueContent"
                        :default-choice="this.valueEquipment"
                        :question="formTexts.needs_local.value_equipment"
                        displayColumn="true"
                        :input-col-size="12"
                        @update:modelValue="checkContent"
                    />
                  </div>
                </div>
              </div>

              <div class="row" v-if="this.valueEquipment && this.valueEquipment !== '600000'">
                <div class="col-10 col-lg-12 mt-4">
                  <div class="row mb-5">
                    <div class="col-12">
                      <RadioInput
                          :inputColSize="0"
                          :question="formTexts.needs_local.nb_sinistres"
                          v-model="nbSinistres"
                          :submitted="submitSinisters"
                          :default-choice="this.nbSinistres"
                          v-bind:class="{ columnDirection: displayColumn }"
                      ></RadioInput>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="this.nbSinistres === '1'" class="row">
                <div class="col-12 col-lg-12 mt-4">
                  <div class="row mb-5">
                    <div class="col-12">
                      <RadioInput
                          :inputColSize="0"
                          :question="formTexts.needs_local.degat_eau"
                          v-model="degatsEaux"
                          :submitted="submitDegatsEaux"
                          :default-choice="this.degatsEaux"
                          v-bind:class="{ columnDirection: displayColumn }"
                      ></RadioInput>
                    </div>
                  </div>
                </div>
              </div>

            </div>
        </div>
      </div>
    </template>
    <template v-slot:viewFooter>
      <FooterNavigation @submitStep="submitForm" @previousStep="previousStep"/>
    </template>
  </ViewContainer>
</template>

<script>
import { mapState } from "vuex";
import FooterNavigation from "../../components/Navigation/FooterNavigation.vue";
import Address from "../../../node_modules/hm-mpe-widgets/src/lib-components/components/FormPmPp/Address.vue";
import RadioInput from "../../../node_modules/hm-mpe-widgets/src/lib-components/components/Widgets/RadioInput.vue";
import SelectInput from "../../../node_modules/hm-mpe-widgets/src/lib-components/components/Widgets/SelectInput.vue";
import ViewContainer from "@/components/Navigation/ViewContainer";
import wrapper from "../../main";
import { reloadEvent } from '../../services/tagCo';

export default {
  name: "NeedsLocal",
  components: { FooterNavigation, RadioInput, SelectInput, ViewContainer, Address },
  data: function () {
    return {
      addressLocal: null,
      statutOccupant: null,
      valueEquipment: null,
      nbSinistres: null,
      degatsEaux: null,
      submitStatusOccupant: false,
      submitContent: false,
      validValueContent: false,
      submitSinisters: false,
      submitDegatsEaux: false,
      addressQuestion: null,
      addresses: [],
      dataAddress: [],
    }
  },
  created() {
    this.updateValue();
    this.createAddressQuestion();
    this.$store.commit("setNextRoute", "NeedsCyber");
    this.checkContent();
    wrapper.setTcVars({
      page_name : 'precisions-local-et-contenu',
      page_cat1_name : 'recueil-de-besoins',
      page_cat2_name : 'tarif-affine',
    });
   // this.addresses=["Paris", "Parseille", "Poitier", "Marseille", "Lyon", "Orléans"];
   // this.addresses = this.$store.dispatch('getAddresses', "23 rue");
    reloadEvent('page');
  },
  methods: {
    async setAddress(value){
      console.log("addresses seted");
       this.dataAddress = await this.$store.dispatch('getAddresses', value);
      
        for(var i = 0; i < this.dataAddress.data.adresses.length; i++) {
        this.addresses[i]=this.dataAddress.data.adresses[i].label
      }
 
    },
    createAddressQuestion() {
      this.addressQuestion = this.formTexts.needs_local.local_address
      let question = this.addressQuestion.text.replace("{{ city }}", this.$store.state.local.postalCodeAndCity.city)
      this.addressQuestion.text = question
    },
    updateValue() {
      this.valueEquipment = this.$store.state.local.content
      this.statutOccupant = this.$store.state.local.statutOccupant
      this.addressLocal = this.$store.state.local.addressLocal
      this.nbSinistres = this.$store.state.local.nbSinistres
      this.degatsEaux = this.$store.state.local.degatsEaux
    },
    checkContent() {
      this.submitContent = false
      if(this.valueEquipment){
        this.validValueContent = true
      }else{
        this.validValueContent = false
      }

    },
    submitForm() {
      this.submitStatusOccupant = true
      this.submitContent = true
      if(this.valueEquipment) {
        this.submitSinisters = true
        if(this.nbSinistres) {
          this.submitDegatsEaux = true
        }
      }
      this.$store.state.local.content = this.valueEquipment
      this.$store.state.local.statutOccupant = this.statutOccupant
      this.$store.state.local.addressLocal = this.$refs.address.submitField()
      this.$store.state.local.nbSinistres = this.nbSinistres
      this.$store.state.local.degatsEaux = this.degatsEaux
      if(this.valueEquipment === "600000" || this.nbSinistres === "2" || (this.nbSinistres === "1" && this.degatsEaux === "non")) {
        this.$store.dispatch('closeOrOpenModal', {
          modalName: 'ModalWcb',
          action: true
        })

        if((this.nbSinistres === "1" && this.degatsEaux === "non")) {
          wrapper.setTcVars({
            page_name : 'degat-des-eaux',
            page_cat1_name : 'exclusion',
            page_cat2_name : '',
          });
          reloadEvent('page');
        }
        if(this.nbSinistres === "2") {
          wrapper.setTcVars({
            page_name : 'trop-de sinistres',
            page_cat1_name : 'exclusion',
            page_cat2_name : '',
          });
          reloadEvent('page');
        }
        if(this.valueEquipment === "600000") {
          wrapper.setTcVars({
            page_name : 'valeur-contenu-trop-haute',
            page_cat1_name : 'exclusion',
            page_cat2_name : '',
          });
          reloadEvent('page');
        }


      } else if(this.$store.state.local.addressLocal && this.valueEquipment && this.nbSinistres) {
        this.nextRoute()
      }
    },
    nextRoute() {
      this.$store.dispatch('updateAvancementNeeds', {
            avancementNumber: 1,
            action: true
        });
      this.$router.push({ name: this.$store.getters.getNextRoute });
    },
    previousStep() {
      this.$router.push({ name: "FastPriceLocal"})
    }
  },
  computed: mapState([`formTexts`]),
}

</script>
