export default {
	setFormTexts(state, data) {
		state.formTexts = data
		state.loadedTexts = true;
	},
	setSelectActivities(state, data) {
		state.selectActivities = data;
	},

	setAvancement(state, data){
		state.avancement[data[0]] = data[1];
	},

	setAvancementNeeds(state, data){
		state.avancementNeeds[data[0]] = data[1];
	},

	setNafList(state, data) {
		state.nafList = data;
	},
	setNextRoute(state, name) {
		state.nextRouteName = name;
	},
	setNeeds(state, data) {
		state.needs = data
	},
	setGaranties(state, data) {
		state.garanties = data
	},
	toggleCyberProtect(state){
		state.cyberProtect = !state.cyberProtect
	},
	setUuid(state, data) {
		state.uuid = data
	},
	setIdProcess(state, data) {
		state.contact.idProcess = data
	},
	setOptins(state, data){
		state.optins = data
	},
	setFormPmPpOptins(state, data){
		state.formPm.optins = data
	},
	setSelectedOffer(state, data){
		state.previousOffer = state.selectedOffer
		state.selectedOffer = data
	},
	setPreviousOffer(state, data){
		state.previousOffer = data
	},
	setAdviceOffer(state, data){
		state.adviceOffer = data
	},
	setJobFamily(state, data){
		state.activity.jobFamily = data
	},
	setModalChangeOffer(state, data) {
		state.modalChangeOffer = data
	},

	setProspect(state, data) {
		state.prospect = data
	},

	setModalActivityHC(state, data) {
		state.modalActivityHC = data
	},
	
	setModalSendMail(state, data) {
		state.modalSendMail = data
	},
	setBetterOffer(state, data) {
		state.betterOffer = data
	},
	setModalWcb(state, data) {
		state.modalWcb = data
	},
	setDocHm(state, data) {
		state.documentsHMDrive = data
	},
	setIsMobil(state, data) {
		state.isMobil = data
	},
	setStickyMobil(state, data) {
		state.stickyMobil = data
	},
	setPrice(state, data) {
		state.price.essentielle = data
		state.price.equilibre = data
		state.price.serenite = data
	},
	setCodeAction(state, data){
		state.codeAction = data
	},
	setActivityCourtage(state, data){
		state.isActivityCourtage = data
	},
	setRcCourtage(state, data){
		state.rcCourtage = data
	},
	setMrProCourtage(state, data){
		state.mrProCourtage = data
	},
	setCodeInsee(state, data){
		state.local.codeInsee = data
	},
	setCities(state, data){
		state.local.cities = data
	},
	setNewCompany(state, data){
		state.newCompany = data
	},
	setOdigoToken(state, data) {
		state.callBack.odigoToken = data
	},
	setOdigoPhone(state, data) {
        state.callBack.phone = data
    },
    setOdigoZipCode(state, data) {
        state.callBack.zipCode = data
    },
    setOdigoDate(state, data) {
        state.callBack.date = data
    },
    setOdigoSlot(state, data) {
        state.callBack.slot = data
    },
	setFailOdigo(state, data) {
		state.callBack.failOdigo = data
	},
	setSuccessOdigo(state, data) {
		state.callBack.successOdigo = data
	},
	setClosableOdigo(state, data) {
		state.callBack.closableOdigo = data
	},
	setTitleWcb(state, data) {
		state.callBack.titleWcb = data
	}
}
