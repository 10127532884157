
export function getTarifOR(state){
    let interlocuteur = ''

    interlocuteur += 'Interlocuteur : ' + state.formPm.civility + ' ' + state.formPm.lastName + ' ' + state.formPm.firstName + '\n'
    interlocuteur += 'Téléphone : ' + state.formPm.phoneNumber + '\n'
    interlocuteur += 'Email : ' + state.formPm.email + '\n'
    interlocuteur += 'Intéressé par : ' + state.selectedOffer + '\n\n'

    let entreprise = ''
    let local = ''
    let statut = ''

    let localData = [state.local.nbBuildings, state.local.superficie, state.local.content, state.local.addressLocal, state.local.postalCodeAndCity.zipCode, state.local.postalCodeAndCity.city, state.local.nbSinistres ];
    for( var data in localData){
        if( localData[Object.keys(localData).indexOf(data)] === null){
            localData[Object.keys(localData).indexOf(data)] = 'non renseigné';
        }
    }

    if(localData[4]=== 'non renseigné' || localData[4]=== 'non renseigné' ){
        localData[3]= 'non renseigné';
        localData[4]= '';
        localData[5]= '';
    }
    
    local += 'Nombre de local: ' + localData[0] + '\n'
    local += 'Superficie : ' + localData[1] + '\n'
    local += 'Valeur de contenu par local : ' + localData[2] + '\n'

    if(state.local.statusOwner === 'autre'){
        if(state.local.statutOccupant === 'free_occupant'){
            statut = 'Occupant à titre gratuit'
        } else if(state.local.statutOccupant === 'subtenant'){
            statut = 'Sous locataire'
        } else {
            statut = 'Locataire agissant pour le compte du propriétaire'
        }
    } else if(state.local.statusOwner === 'tenant'){
        statut = 'Locataire'
    } else {
        statut = 'Propriétaire'
    }

    if(!state.activity.isMrPro){
        statut = "non renseigné"
    }

    local += 'Statut occupant : ' + statut + '\n'
    local += 'Adresse : ' + localData[3] + ' ' + localData[4] + ' ' + localData[5] + '\n'
    local += 'Nombre sinistre : ' + localData[6] + '\n'

    entreprise += state.formPm.createdDate != '' ?  'Date de création : ' + state.formPm.createdDate + '\n' : '\n'
    entreprise += "Chiffre d'affaire : " + state.activity.ca + '\n'
    entreprise += state.cyberProtect ? "Dispose d'un site internet et/ou d'une base de données : OUI \n" : "Dispose d'un site internet et/ou d'une base de données : NON \n"
    entreprise += local

    let observation = '';
    observation += state.activity.isMrPro ? 'MR PRO' : 'RC sèche'
    observation += state.cyberProtect ? ' + cyber \n' : '\n'
    observation += interlocuteur
    observation += entreprise

    return {
        "statut":"A faire",
        "codeAction": state.codeAction ? state.codeAction  : 'WEB_PRO_SEO_NA',
        "flagCallCenter": false,
        "objet": "Affichage tarif",
        "canal":"Web",
        "evtDeclencheur": "Vu sur internet",
        "observation": observation,
        "garantie": "MR PRO",
        "priorite":"80",
        "motif":"Opportunite Relationnelle"
    }
}

export function getDevisOR(state){
    return getOR("Devis envoyé", state, "85", null)
}

export function getSouscriptionOR(state){
    return getOR("Initialisation souscription", state, "91", null)
}

export function getRIAOR(state){
    return getOR("Attente Signature électronique", state, "93", null)
}

export function getSignatureOR(state){
    return getOR("Attente Signature électronique", state, "92", null)
}

export function getValidationOR(state){
    return getOR("Souscription enregistrée", state, null, "Fait")
}

function getOR(ORName, state, ORpriorite, ORstatut){
    let OR = {
        "objet": ORName,
        "flagCallCenter": false,
        "codeAction": state.contact.codeAction ? state.contact.codeAction  : 'code_action',
        "evtDeclencheur": "Vu sur internet",
    };

    if (ORpriorite){
        OR["priorite"] = ORpriorite
    }

    if (ORstatut){
        OR["statut"] = ORstatut
    }

    return OR;
}
