import Swiper, { Navigation, Pagination } from "swiper";

export default {


    initSwiper(focus, nbSlidesPerView) {

        let nbToDisplay = 1;
        if (window.screen.width > 789) {
            nbToDisplay = nbSlidesPerView;
        }

        const swiper = new Swiper('.swiper', {
            slidesPerView: nbToDisplay,
            modules: [Navigation, Pagination],
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
        if (focus) {
            swiper.activeIndex = focus
        }
        if (swiper.length > 1) {
            swiper.forEach((s) => s.navigation.update())
        } else {
            swiper.navigation.update()
        }
    },

    convertToSlug(str) {
        str = str.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();

        // remove accents, swap ñ for n, etc
        var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
        var to   = "aaaaaeeeeeiiiiooooouuuunc------";
        for (var i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '-') // collapse whitespace and replace by -
            .replace(/-+/g, '-'); // collapse dashes

        return str;
    }
}
