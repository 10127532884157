import axios from "axios";
import api, {getUpdateStateData} from "@/services/api";
import {getActivityFromNaf} from "@/services/jobFamilyService";
import ods from "@/services/ods";
import {buildAuthenticateData, buildCallbacksData} from "@/services/odigoAPI/odigoCallBuilder";
import textHelper from "@/helpers/textHelper";
import garantiesHelper from "@/helpers/garantiesHelper";

export default{
	updateState(context) {
		context.state.stateLoaded = false
		axios.post(process.env.VUE_APP_DRUPAL_URL + '/api/parcours-soumissions', getUpdateStateData(context.state)).then(r => {
			context.state.stateLoaded = true
			console.log(r);
			// return r
		});
	},
	resetApiData(context) {
		context.state.prospect = null
		context.state.fastPrice = null
		context.state.questionSteps = null
	},

	updateAvancement(context, value) {
		context.commit('setAvancement', [value.avancementNumber, value.action] );
	},

	updateAvancementNeeds(context, value) {
		context.commit('setAvancementNeeds', [value.avancementNumber, value.action] );
	},

	getFormTexts(context) {
		// axios
		// 	.get(process.env.VUE_APP_DRUPAL_URL+  '/hm-parcours-web/get-text/mr_pro')
		// 	.then(function (response) {
		// 		context.commit('setFormTexts', response.data);
		// 	})
		context.commit('setFormTexts', textHelper);
		context.commit('setGaranties', garantiesHelper);
	},

	getActivities(context) {
		ods.get(`addagio/v1/activites-detail`).then((r) => {
			context.commit('setSelectActivities', r.data.map( (a) => {
				return { id : [a.codeNafDetail, a.assureur[0]], text : a.codeNafDetail + ' - ' + a.libelleNafDetail };
			}))
			context.commit('setNafList', r.data.map((a) => {
				return {id : a.codeNafDetail, text: a.libelleNafDetail};
			}))
		})
	},

	getProduct(context) {
		let data = {
			"nafDetails": [
				{
					"codeNafDetail": context.state.activity.prospectActivity.split(',')[0]
				}
			],
			"chiffreAffaires": context.state.activity.ca,
			"garantiesMrproRc": context.state.activity.productOds
		}
		return ods.post(`addagio/v2/recommandations`, data).then((r) => {
			context.commit('setNeeds', r.data.produitsCommerciaux)
		})
	},

	getFastPrice(context){
		context.dispatch("getAllSteps").then(() => {
			context.dispatch("answerAll").then(() => {
				api.get(`/prospects/${context.state.prospect.id}/offers`).then((r)=> {
					context.state.fastPrice = r.data.data["vyv-iard-container"].choices.economique.price
				})
			})
		})
	},

	createProspect(context) {
		return api.post('prospects', {
			"email": "agabriel@plussimple.com",
			"phone": "+33617323273",
			"owner_status": "M",
			"owner_firstname": "Adrien",
			"owner_lastname": "GABRIEL",
			"postal_code": context.state.local.postalCodeAndCity.zipCode,
		}).then((r) => {
			context.state.prospect = r.data.data;
		})
	},

	updateProspect(context, value) {
		context.commit('setProspect', value);
	},

	addProduct(context) {
		return api.post(`prospects/${context.state.prospect.id}/products`, {"slug": "vyv-iard-container"})
	},

	answerAll(context) {
		let activity = context.state.activity.prospectActivity.split(',')[0]
		let data = {
			"vyv-iard-container": {
				"criterias": [
					{
						"key": "needs",
						"value": context.state.activity.product
					},
					{
						"key": "turnover",
						"value": context.state.activity.ca
					},
					{
						"key": "activities",
						"value": [
							{
								"code": activity,
								"turnover_part": 100
							}
						]
					},
					{
						"key": "has_creator_decrease",
						"value": false
					},
				]
			}
		};
		return api.put(`prospects/${context.state.prospect.id}/criterias`, data).then(() => {
			return context.dispatch("recursiveSteps")
		})
	},

	recursiveSteps(context) {
		return context.dispatch("getAllSteps").then(() => {
			if (context.state.questionSteps.next_step) {
				return api.get(`/prospects/${context.state.prospect.id}/questions-steps/${context.state.questionSteps.next_step}`).then(async (r) => {
					let responses = []
					if(!context.state.local.codeInsee){
						await context.dispatch('getCodeInsee');
					}
					if ("vyv-iard-container-sites-form" === context.state.questionSteps.next_step) {
						if(context.state.fastPrice === null) {
							let content = await context.dispatch("getContentValue")
							let postalCode = "54000"
							let city = "Nancy"
							if(context.state.local.postalCodeAndCity.zipCode !== null) {
								postalCode = context.state.local.postalCodeAndCity.zipCode
								city = context.state.local.postalCodeAndCity.city
							}
							let statusFastPrice = ''
							if(context.state.local.statusOwner === 'autre') {
								statusFastPrice = "tenant"
							} else {
								statusFastPrice = context.state.local.statusOwner
							}
							responses = [
								{
									"key": "buildings",
									"value": [
										{
											"address": "1 rue Saint Dizier",
											"city": city,
											"insee_code_city": context.state.local.codeInsee,
											"postal_code": postalCode,
											"surface": context.state.local.superficie,
											"content": content,
											"occupant_status": statusFastPrice
										}
									]
								}
							]
						} else {
							let status = ''
							if(context.state.local.statusOwner === 'autre') {
								status = context.state.local.statutOccupant
							} else {
								status = context.state.local.statusOwner
							}
							responses = [
								{
									"key": "buildings",
									"value": [
										{
											"address": context.state.local.addressLocal,
											"city": context.state.formPm.postalCodeAndCity.city,
											"insee_code_city": context.state.local.codeInsee,
											"postal_code": context.state.formPm.postalCodeAndCity.zipCode,
											"surface": context.state.local.superficie,
											"content": context.state.local.content,
											"occupant_status": status
										}
									]
								}
							]
						}
					}else{

						r.data.data.questions.map(function (q) {
							let value = q.values[0].value
							if ("vyv-iard-container-number-of-sites-form" === context.state.questionSteps.next_step){
								value = 1
							}
							if(q.id === "has_commercial_decrease") {
								value = false
							}
							responses.push({
								"key": q.id,
								"value": value,
							})
						})
					}

					let dataToSend = {
						"vyv-iard-container": {
							"criterias": responses,
							"step": context.state.questionSteps.next_step
						}
					};

					return api.put(`prospects/${context.state.prospect.id}/criterias`, dataToSend).then(() => {
						return context.dispatch("recursiveSteps");
					})
				});
			} else {
				return new Promise(function(resolve) { // not taking our time to do the job resolve("success"); // immediately give the result: 123 });
					resolve(true)
				})
			}
		})
	},

	getAllSteps(context) {
		return api.get(`/prospects/${context.state.prospect.id}/questions-steps`).then((r) => {
			context.state.questionSteps = r.data.data["vyv-iard-container"]
		})
	},

	createProspectPrice(context) {
		let prospect = {
			"owner_status": context.state.formPm.civility,
			"owner_firstname": context.state.formPm.firstName,
			"owner_lastname": context.state.formPm.lastName,
			"city": context.state.formPm.postalCodeAndCity.city,
			"address": context.state.formPm.addressSiege,
			"email": context.state.formPm.email,
			"postal_code": context.state.formPm.postalCodeAndCity.zipCode,
		}
		return api.post('prospects', prospect).then((r) => {
			context.state.prospect = r.data.data
		})
	},

	getCodeInsee(context) {
		return api.get(`cities?q=${context.state.local.postalCodeAndCity.zipCode}`).then((data) => {
			data.data.data.forEach(city => {
				if(city.name === context.state.local.postalCodeAndCity.city) {
					context.commit('setCodeInsee', city.insee_code)
				}
			});
		})
	},

	getCities(context, zipCode) {
		let cities = [];
		api.get(`cities?q=${zipCode}`).then((data) => {
			console.log(data);
			data.data.data.forEach(city => {
				cities.push(city.name);
				context.commit('setCities', cities)
			})
		});
		
	},

	
	getAddresses(context, address) {
		return api.get(process.env.VUE_APP_DRUPAL_URL + `/hm-parcours-web/search-adress/` + address)
	},
	initProduct(context) {
		let activity = context.state.activity.prospectActivity.split(',')[0]
		let hasCreatorDecrease = false
		let date = new Date()
		// aaaa-mm-jj
		if(date - Date.parse(context.state.formPm.createdDate) < 15778800000) {
			hasCreatorDecrease = true
		}
		let product = {
			"vyv-iard-container": {
				"criterias": [
					{
						"key": "needs",
						"value": context.state.activity.product
					},
					{
						"key": "turnover",
						"value": context.state.activity.ca
					},
					{
						"key": "activities",
						"value": [
							{
								"code": activity,
								"turnover_part": 100
							},
						]
					},
					{
						"key": "has_creator_decrease",
						"value": hasCreatorDecrease
					},
				]
			}
		}
		return api.put(`/prospects/${context.state.prospect.id}/criterias`, product)
	},

	getPrice(context) {
		return api.get(`/prospects/${context.state.prospect.id}/offers`).then((r)=> {
			context.state.price.essentielle = r.data.data["vyv-iard-container"].choices.economique
			context.state.price.equilibre = r.data.data["vyv-iard-container"].choices.equilibre
			context.state.price.serenite = r.data.data["vyv-iard-container"].choices.complet
		})
	},

	createPrice(context) {
		context.dispatch("createProspectPrice").then(() => {
			return context.dispatch("addProduct").then(() => {
				return context.dispatch("initProduct").then(() => {
					return context.dispatch("recursiveSteps").then(() => {
						return context.dispatch("getPrice")
					})
				})
			})
		})
	},

	createContactProcessus(context) {
		axios.post(process.env.VUE_APP_DRUPAL_URL + '/hm-web-services/create-id-processus').then((r) => {
			context.commit('setIdProcess', r.data)
		}).catch((error) => {
			throw error;
		})
	},
	createContact(context, data) {
		return axios.post(process.env.VUE_APP_DRUPAL_URL + '/hm-web-services/create-pm', data).then(r => {return r});
	},
	getOptins(context){
		axios.get(process.env.VUE_APP_DRUPAL_URL + '/hm-web-services/consentements').then((r) => {
			context.state.isConsentementAllRight = true;
			context.commit('setOptins', r.data.mentionOptin);
		}).catch(function(error){
			context.state.isConsentementAllRight = false;
			error instanceof Error;
			throw error;
		});
	},

	closeOrOpenModal(context, value) {
		context.commit('set' + value.modalName, value.action)
	},

	isBetterOffer(context, value) {
		context.commit('setBetterOffer', value)
	},

	cancelChange(context) {
		context.commit('setSelectedOffer', context.state.previousOffer)
	},

	createStudy(context, data) {
		return axios.post(process.env.VUE_APP_DRUPAL_URL + '/hm-web-services/create-study-v3/coll', data).then((r) => {
			context.commit('setDocHm', r.data.documentsHMDrive)
		})
	},

	sendMail(context, data) {
		return axios.post(process.env.VUE_APP_DRUPAL_URL + '/hm-web-services/doc-mail/mrpro', data)
	},

	updatePm(context, data) {
		return axios.post(process.env.VUE_APP_DRUPAL_URL + '/hm-web-services/update-pm-pp/' + context.state.contact.idProcess + '/pm', data)
	},

	isMobil(context, value) {
		context.commit('setIsMobil', value)
	},

	isStickyMobil(context, value) {
		context.commit('setStickyMobil', value)
	},

	resetPrice(context) {
		context.commit('setPrice', null)
	},

	async checkActivityCourtage(context, codeNafDetail) {
		ods.get('addagio/v1/activites/' + codeNafDetail.substr(0,5)).then((r) => {
			let activity
			r.data.activitesDetail.forEach(element => {
				if(element.codeNafDetail === codeNafDetail) {
					activity = element
				}
			});
			if(activity.partenaireRC === "RC COURTAGE"){
				context.commit('setRcCourtage', true)
			} else {
				context.commit('setRcCourtage', false)
			}
			if(activity.partenaireMRPRO === "MR PRO COURTAGE"){
				context.commit('setMrProCourtage', true)
			} else {
				context.commit('setMrProCourtage', false)
			}
		})
	},

	async getContentValue(context) {
		let caValue = [100000, 200000, 300000, 400000, 500000, 750000, 1000000, 1250000, 1500000]
		let arrayActivityFamily
		let ca = context.state.activity.ca
		let jobFamily = context.state.activity.jobFamily


		if(jobFamily === 'autre_service' || jobFamily === 'comm_ali' || jobFamily === "commerce" || jobFamily === "hcr") {
			let activityFamily = await getActivityFromNaf(context.state.activity.prospectActivity)

			if(['conseil', 'informatique', 'marketing', 'media'].includes(activityFamily)) {
				arrayActivityFamily = [10000, 10000, 10000, 10000, 10000, 10000, 30000, 30000, 30000]
			}
			if(['service_hl_borloo', 'service_borloo', 'local_storage'].includes(activityFamily)) {
				arrayActivityFamily = [10000, 10000, 10000, 10000, 10000, 30000, 30000, 30000, 30000]
			}
			if(['manda_immo'].includes(activityFamily)) {
				arrayActivityFamily = [10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000]
			}
			if(['comm_ali', 'commerce', 'hotel', 'rcbg'].includes(activityFamily)) {
				arrayActivityFamily = [30000, 30000, 30000, 70000, 70000, 70000, 150000, 150000, 150000]
			}
			if(['boulanger_patissier'].includes(activityFamily)) {
				arrayActivityFamily = [30000, 30000, 70000, 70000, 70000, 150000, 150000, 150000, 150000]
			}

		} else {

			if(['fleuriste'].includes(jobFamily)) {
				arrayActivityFamily = [10000, 10000, 10000, 30000, 30000, 30000, 70000, 150000, 150000]
			}
			if(['sb'].includes(jobFamily)) {
				arrayActivityFamily = [10000, 10000, 30000, 30000, 30000, 70000, 70000, 70000, 70000]
			}
			if(['bien_etre', 'paramedical'].includes(jobFamily)) {
				arrayActivityFamily = [10000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000]
			}
			if(['secu'].includes(jobFamily)) {
				arrayActivityFamily = [10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000, 10000]
			}
		}

		let index

		for(let i = 0; i < caValue.length ; i++) {
			if(ca <= caValue[i]) {
				index = i
				break
			}
		}

		return arrayActivityFamily[index]
	},

	resetInfoOdigo(context) {
		context.commit('setSuccessOdigo', false)
		context.commit('setFailOdigo', false)
		context.commit('setClosableOdigo', true)
		context.commit('setModalWcb', false)
		context.commit('setTitleWcb', "Contacter un conseiller Harmonie Mutuelle")
	},

	getOdigoPhone(context, data){
		context.commit('setOdigoPhone', data)
	},

	getOdigoZipCode(context, data){
		context.commit('setOdigoZipCode', data)
	},

	getOdigoDate(context, data){
		context.commit('setOdigoDate', data)
	},

	getOdigoSlot(context, data){
		context.commit('setOdigoSlot', data)
	},

	setFailOdigoValue(context, data) {
		context.commit('setFailOdigo', data)
	},
	
	setSuccessOdigoValue(context, data) {
		context.commit('setSuccessOdigo', data)
	},

	setClosableOdigoValue(context, data) {
		context.commit('setClosableOdigo', data)
	},

	setTitleWcbValue(context, data) {
		context.commit('setTitleWcb', data)
	},

	async getOdigoToken(context) {
		let data = await buildAuthenticateData();
		return axios.post(process.env.VUE_APP_DRUPAL_URL + '/hm-web-services/odigo-token', data).then((r) => {
			context.commit('setOdigoToken', r.data.accessToken)
		})
	},

	async getOdigoCallback(context) {
		try {
			let data = await buildCallbacksData(context.state.callBack, context.state.codeAction)
			let res = await axios.post(process.env.VUE_APP_DRUPAL_URL + '/hm-web-services/odigo-callback', data)
			if(res.status === 200 || res.status === 201 || res.status === 202) {
				context.commit('setSuccessOdigo', true)
			}
		} catch(error) {
			if(error.response.status === 400) {
				context.commit('setFailOdigo', true)
			}
		}
	},

	setIsNewCompany(context, data) {
		context.commit('setNewCompany', data);
	},

	setModalWcb(context, data) {
		context.commit('setModalWcb', data);
	}
}
