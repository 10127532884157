export async function buildAuthenticateData() {
    return {
        "appUid": "blU9Xk3P0rkEB1ja5O1hwvsYyfF3",
        "userUid": "webmarketing@harmonie-mutuelle.fr",
        "ttl": 3600
    }
}

export async function buildCallbacksData(callBack, codeAction) {
    const slot = getType(callBack.slot);

    return {
        'token': {
            'odigoToken': callBack.odigoToken
        },
        'callBack': {
            "phoneNumber": callBack.phone,
            "priority": "0",
            "skillKeyword": getSkillKeyword(callBack.zipCode),
            "date": convertDateToFr(callBack.date),
            "data": "MRPRO / " + codeAction,
            "agentId": "",
            "slot": slot === '0'? slot : slot + ':00:00',
        }
    }
}

export function getSkillKeyword(zipCode) {
    let skillKeyword;

    if(['16','44','49','53','72','85','97','98','14','22','27','29','35','50','56','61','76'].includes(zipCode.substr(0,2))) {
        skillKeyword = "GOWCBPRO";
    } else if(['18','21','28','36','37','41','45','58','71','75','77','78','89','91','92','93','94','95'].includes(zipCode.substr(0,2))) {
        skillKeyword = "PEWCBPRO";
    } else if(['02','08','10','25','39','51','52','54','55','57','59','60','62','67','68','70','80','88','90'].includes(zipCode.substr(0,2))) {
        skillKeyword = "PEWCBPRO";
    } else if(['01','03','04','05','06','07','13','15','26','30','34','38','42','43','48','63','69','73','74','83','84','20'].includes(zipCode.substr(0,2))) {
        skillKeyword = "GSWCBPRO";
    } else if(['17','79','86'].includes(zipCode.substr(0,2))) {
        skillKeyword = "GSWCBPRO";
    } else if(['09','11','12','19','23','24','31','32','33','40','46','47','64','65','66','81','82','87'].includes(zipCode.substr(0,2))) {
        skillKeyword = "GSWCBPRO";
    }

    return skillKeyword
}

export function getType(slot) {
    let type

    if(slot === "0h") {
        type = '0'
    } else if (slot === "9h") {
        type = '09'
    } else {
        type = slot.substring(0,2)
    }

    return type
}

export function getCallBackDate(slot, date) {
    let dateTimestamp;
    let hour = getType(slot)

    if(hour === '0') {
        dateTimestamp = 0
    } else {
        let index = 16
        let newHour = hour + ':00:00'
        let newDate = date.toString().slice(0, index) + newHour + date.toString().slice(index + 8)
        dateTimestamp = (new Date(newDate).getTime()) / 1000
    }
    return dateTimestamp;
}

export function convertDateToFr(date) {
    if (date !== 'undefined') {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    } else {
        return null;
    }
}