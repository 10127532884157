<template>
  <div class="pt-3 pb-2">
    <div class="pt-2 pb-2 exclusivite row">

        <div class="col-auto align-self-center"> <img :src="require('@/assets/svg/star.svg')" /> </div>
        <div class="col-auto align-self-center exclu-text">Exclusivité Harmonie Mutuelle</div>
    </div>
  </div>
  </template>
  <script>
    export default {
      name: "ExcluLabel",

    }
    </script>
    
    <style scoped>
    
    </style>
    