<template>
  <div class="container">
    <div class="row justify-content-center mt-5">
      <div class="col-11 col-md-12 text-white">
        <div class="row justify-content-center">
          <div class="col-12 callback-row">
            <div class="row justify-content-around align-items-center py-4">
              <div class="col-12 col-md-5 text-center text-md-start">
              <span class="pe-2">
                <img :src="require('@/assets/svg/arrow-title-white.svg')" />
              </span>
                <span class="callBack">
                {{ formTexts.needs_price.callback_titre.text }}
              </span>
              </div>
              <div class="col-12 col-md-5 text-end py-3">
                <a
                    @mouseover="showHover = true"
                    @mouseleave="showHover = false"
                    class="btn btn-outline-light border border-3 rounded-pill px-4 py-2 callBackText"
                    @click="contactConseiller()">
                  <img
                      v-show="!showHover"
                      class="pe-3 callBackImg"
                      :src="require('@/assets/svg/icon-callback.svg')"/>
                  <img
                      v-show="showHover"
                      class="pe-3 callBackImg"
                      :src="require('@/assets/svg/icon-callback-hover.svg')"/>
                  {{ formTexts.needs_price.callback_button.text }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {mapState} from "vuex";
import { reloadEvent } from "../../services/tagCo";

export default {
  name: "CallbackRow",
  computed: mapState([`formTexts`]),
  methods:{
    contactConseiller(){
      let event = {
        click_name: 'contact',
        click_type: 'action',
        composant: 'bandeau'
      };
      reloadEvent('click', event)
      window.open(this.formTexts.needs_price.callback_button.link, '_blank').focus();
    }
  }

}
</script>

<style scoped>

</style>
