<template>
  <div class="container footer-icons">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="row">
          <div class="col"><hr></div>
        </div>
        <div class="row justify-content-around py-3 py-md-5">
          <div class="col-12 col-sm-6 col-md-2 text-center" v-for="iconName in iconNames" :key="iconName">
            <img class="mb-3" :src="require('@/assets/svg/'+ iconName +'.svg')"/>
            <span style="overflow-wrap: break-word;" v-html="formTexts.footer_icons[iconName + '_label'].text"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "FooterIcons",
  data: () => {
    return {
      iconNames : ['calendar', 'marker', 'people', 'recommandation']
    }
  },
  computed: mapState([`formTexts`])
}
</script>
