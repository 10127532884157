<template>

  <router-view/>

  <div style="cursor: pointer">
    <Contact />
  </div>

</template>

<script>
import { mapState } from "vuex";
import Contact from "@/components/Navigation/Contact";

export default {
  name: "Wizard",
  components: {Contact},
  data: function () {
    return {
      linkTexts: Object,
      completed: null,
      completedSouscription: null
    };
  },
  created() {
    this.completed=this.$store.state.avancement;


    if(this.contact.idProcess === null && ['NeedsCyber', 'NeedsProtection', 'NeedsCoordonate', 'NeedsPrice'].includes(this.$route.name)){
      this.$router.push({ name: 'FastPrice' });
     // location.reload();
    }

    //overrideBrowserBackLink(this.$router);
    //location.reload();
     if ( this.contact.idProcess === null && ['Situation', 'VotreSituation', 'InformationsPro', 'Besoins', 'Prevoyance', 'Prevoyance', 'CouvertureSante1', 'CouvertureSante2', 'InfosPersos', 'InformationsPersonnelles', 'InformationsPersonnellesComplete', 'Devis' ].includes(this.$route.name)){
       this.$router.push({ name: 'Situation' });
     }
  },
  computed: mapState([`formTexts`, `contact`]),
};
</script>
