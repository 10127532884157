export function getFamilyFromNaf(completeCodeNaf){
	const jobFamilyNafCodes = {
		hcr: ['55', '561', '5629', '563'],
		fleuriste: ['4776Z1'],
		commerce : [ '4711A', '4711D', '4711E', '4711F', '4719', '4726', '473', '474',
			'475', '476', '477', '4782', '4789', '479', '9511', '9522', '9523', '9529', '960B1', '960B2' ],
		comm_ali: ['101', '107', '4711B', '4711C', '472', '4781', '5621'],
		sb: ['9602A2', "9602B4", "9602B5"],
		sb_domicile: ['9602A1', '9602B1'],
		bien_etre: ['8551Z6', '8690F', '9604Z'],
		autre_service : ['411', '58', '59', '6', '7', '803', '81', '82', '8552', '8553', '8559', '856',
			'881', '900', '92', '9512', '9521', '9524', '9525', '9603', '960A1', '9609'
		],
		paramedical: ['8690E', '8690D', '9602B3'],
		secu : ['801', '802']
	}

	let codeNaf = completeCodeNaf.split(',')[0];

	let jobKey = null;
	for (const [key, value] of Object.entries(jobFamilyNafCodes)) {
		for (const naf of value){

			if (['8690F57', '8690F1'].includes(codeNaf) ){
				jobKey = 'autre_service'
				break
			}
			if ('9604Z1' === codeNaf){
				jobKey = 'sb'
				break
			}

			if (naf === codeNaf.substring(0,naf.length)){
				jobKey = key
				break
			}
		}
		if (jobKey !== null) break
	}
	return jobKey
}

export function getActivityFromNaf(completeCodeNaf){
	const jobActivityNafCodes = {
		local_storage: ['4782', '4791', '4799'],
		boulanger_patissier: ['1071'],
		conseil: ['6202', '7021', '7022', '7220'],
		informatique: ['5821', '5829', '6110', '6120', '620', '631', '7022'],
		manda_immo: ['6831'],
		marketing:['6201', '702', '731', '7320', '8230'],
		media:['581', '591', '6010', '6020', '6391', '8552', '900'],
		service_hl_borloo:['6902', '74', '7990', '8030', '8121', '82', '855', '856', '869', '9609'],
		service_borloo:['8810'],
		comm_ali: ['1013', '4711', '472', '4781', '5621'],
		commerce: ['4711', '474', '475', '476', '477', '951', '952', '9601'],
		hotel: ['5510'],
		rcbg: ['5610', '5630'],
	}

	let codeNaf = completeCodeNaf.split(',')[0];

	let activityKey = null;
	for (const [key, value] of Object.entries(jobActivityNafCodes)) {
		for (const naf of value){

			if (naf === codeNaf.substring(0,naf.length)){
				activityKey = key
				break
			}
		}
		if (activityKey !== null) break
	}
	return activityKey
}
