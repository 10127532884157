<template>
  <div class="footer-navigation" :class="{'container' : isContainer}">
    <div class="row align-items-center" :class="rowClass">
      <div :class="backLinkClass">
        <div v-if="$route.name !== 'FastPriceActivity' && $route.name !== 'NeedsPrice' && modalWcb !== true">
          <img :src="require('@/assets/svg/Icon feather-arrow-left.svg')"/>
          <a class="back-button ms-2" @click="previousStep()">{{ textLeft }}</a>
        </div>

      </div>
      <div :class="nextLinkClass" v-if="$route.name !== 'NeedsPrice' || modalWcb === true">
        <a v-if="disabledButtonLabel != null" class="btn btn-link" disabled>{{ disabledButtonLabel }}</a>
        <a v-else class="btn btn-info rounded-pill" :class=" {'disabled' : isDisabled} " @click="submitStep()" data-cy="next-step">
          <span class="me-4 pe-4 ms-2">{{ textRight }}</span>
          <img :src="require('@/assets/svg/arrow-title-white.svg')" /></a>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from "vuex";

export default {
  name: "FooterNavigation",
  components :{
  },
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    },
    textLeft: {
      type: String,
      default: "Retour"
    },
    textRight: {
      type: String,
      default: "Suivant"
    },
    disabledButtonLabel: {
      type: String,
      default: null
    },
    backLinkClass:{
      type: String,
      default: 'col-auto me-auto'
    },
    nextLinkClass:{
      type: String,
      default: 'col-auto'
    },
    rowClass:{
      type: String,
      default: 'py-5'
    },
    isContainer: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    submitStep() {
      this.$emit("submitStep");
    },
    previousStep(){
      this.$emit("previousStep");
    }
  },
  computed: mapState([`modalWcb`])
}
</script>

<style scoped>

</style>
