<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">

        <div
            v-if="this.$route.path.includes('souscription')"
            id="nav"
            class="row justify-content-center mt-4 mb-4">
        </div>

        <div
            v-else
            id="nav"
            class="row justify-content-center mt-4">
          <div class="col-lg-8">
            <div class="row justify-content-center gx-0 gx-md-2">
              <NavLink
                  step-index="1"
                  :complet="completed[1]"
                  route-name="FastPriceActivity"
                  :link-text="formTexts.tarif_rapide.tarif_rapide_activite.text"
              />

              <NavLink
                  step-index="2"
                  :complet="this.$store.state.avancement[2]"
                  route-name="FastPriceLocal"
                  :link-text="formTexts.tarif_rapide.tarif_rapide_local.text"
              />

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

 <router-view> </router-view>
</template>
  
<script>
import { mapState } from "vuex";
import NavLink from "@/components/Navigation/NavLink";

export default {
  name: "FastPrice",
  components: { NavLink },
  data: function () {
    return {
      linkTexts: Object,
      completed: null,
      completedSouscription: null,
      filAriane: null
    }
  },
  created() {
    this.completed=this.$store.state.avancement;

    // if ( this.contact.idProcess === null && ['Situation', 'VotreSituation', 'InformationsPro', 'Besoins', 'Prevoyance', 'Prevoyance', 'CouvertureSante1', 'CouvertureSante2', 'InfosPersos', 'InformationsPersonnelles', 'InformationsPersonnellesComplete', 'Devis' ].includes(this.$route.name)){
    //   this.$router.push({ name: 'Situation' });
    // }
  },
  computed: mapState([`formTexts`, `contact`]),
}
</script>

<style scoped>

</style>
