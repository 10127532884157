<template>
  <div v-for="category in Object.keys(this.garanties.labels)" :key="category">

    <div class="garantees-table-container" v-if="isCategoryVisible(category)" v-show="showCyberProtect(category)">

      <div class="d-block sup-category-title">
        <div v-if="category === 'Responsabilité civile'">
          <h3 v-if="this.$store.state.activity.isMrPro" class="d-block d-md-inline-block">Détails des garanties dommage aux biens, responsabilité civile et franchises</h3>
          <h3 v-if="!this.$store.state.activity.isMrPro" class="d-block d-md-inline-block">Détails des garanties Responsabilité civile</h3>
          <a class="d-block d-md-inline-block float-start float-md-end" target="_blank"
             v-if="getJobPdfLink('pdf_rc')" :href="getJobPdfLink('pdf_rc')"
             @click="downloadPdf(getJobPdfLink('pdf_rc'))">Télécharger le détail des garanties</a>
        </div>
        <div v-if="category === 'Franchises'">
          <a class="d-block d-md-inline-block float-start float-md-end" target="_blank"
             @click="downloadPdf(getJobPdfLink('dab_pdf'))"
             v-if="!this.rc ? getJobPdfLink('dab_pdf') : ''" :href="getJobPdfLink('dab_pdf')">Télécharger le détail des garanties</a>
        </div>
        <div v-if="category === 'Protection juridique professionnelle'">
          <h3  class="d-block d-md-inline-block">Détails des garanties protection juridique professionnelle</h3>
          <a class="d-block d-md-inline-block float-start float-md-end" target="_blank" v-if="getCouverturePdfLink(category)"
             @click="downloadPdf(getCouverturePdfLink(category))"
             :href="getCouverturePdfLink(category)">Télécharger le détail des garanties</a>
        </div>
        <div v-if="category === 'Protection Cyber-Attaque'">
          <h3  class="d-block d-md-inline-block">Détails des garanties protection cyber</h3>
          <a class="d-block d-md-inline-block float-start float-md-end" target="_blank" v-if="getCouverturePdfLink(category)"
             @click="downloadPdf(getCouverturePdfLink(category))"
             :href="getCouverturePdfLink(category)">Télécharger le détail des garanties</a>
        </div>
      </div>

      <div class=" row justify-content-start">
        <h4 class="d-block category-title col-auto">{{ category }}</h4>
        <ExcluLabel class="col-auto" v-if="category == 'Garanties spécifiques à votre activité' || category == 'Les garanties financières'"></ExcluLabel>

      </div>


      <a v-if="this.garanties.garanties[this.jobName][getPdfKey(category)]" class="d-block d-md-inline-block" target="_blank" :href="this.garanties.garanties[this.jobName][getPdfKey(category)]">Pdf</a>

      <div v-for="subCategory in Object.keys(this.garanties.labels[category])" :key="subCategory">
        <table class="table table-striped"  v-if="isSubCategoryVisible(subCategory) || !isCategorySelected(subCategory)">
          <tbody>
          <tr class="sub-category-row" v-if="isCategorySelected(subCategory)">
            <td colspan="3" class="sub-category">
              <GarantyAccordion :category="category" :sub-category="subCategory"
                                :garanties-labels="garanties.labels" />
            </td>
          </tr>
          <tr class="sub-category-row-checks text-center"
              v-if="isCategorySelected(subCategory) && !('offers' in this.garanties.garanties[this.jobName][subCategory])">
            <td v-for="n in 3" :key="n">
              <i class="bi bi-check2"></i>
            </td>
          </tr>
          <tr class="row text-center sub-category-row-offers"
              v-else-if="isCategorySelected(subCategory) && 'offers' in this.garanties.garanties[this.jobName][subCategory]">
            <td v-for="amount in this.garanties.garanties[this.jobName][subCategory]['offers']"
                :key="amount" class="col">
              <div class="d-block" v-if="category === 'Franchises'">
                Franchise
              </div>
              <div class="d-block remboursement" v-if="getRemboursementLabel(category, subCategory)">
                Remboursement jusqu'à
              </div>
              <strong>{{ amount }}</strong>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>

  </div>
</template>

<script>
import GarantyAccordion from "@/components/NeedsPrice/GarantyAccordion";
import {mapState} from "vuex";
import ExcluLabel from "@/components/NeedsPrice/ExcluLabel.vue";
import templateHelpers from "@/services/templateHelpers";
import { reloadEvent } from "../../services/tagCo";

export default {
  name: "GaranteesTable",
  components: {GarantyAccordion, ExcluLabel},
  props: {
    garanties : null,
    jobName: String,
    rc: Boolean
  },
  methods: {
    ...templateHelpers,
    isCategoryVisible(category){
      let subCategorySelected = false
      if (this.rc && ['Responsabilité civile', 'Protection juridique professionnelle', 'Franchises'].includes(category)) {
        subCategorySelected = true
      }
      if (!this.rc) {
        Object.keys(this.garanties.labels[category]).forEach((value) => {
          if (value in this.garanties.garanties[this.jobName] && this.garanties.garanties[this.jobName][value]['selected']){
            subCategorySelected = true
          }
        })
      }
      if (this.cyberProtect && category === 'Protection Cyber-Attaque'){
        subCategorySelected = true
      }
      return subCategorySelected
    },
    showCyberProtect(category){
      // On affiche toutes les catégories autre que Cyber Protect
      if (category !== 'Protection Cyber-Attaque') return true
      if (this.cyberProtect && category === 'Protection Cyber-Attaque') return true
      if (!this.cyberProtect && category === 'Protection Cyber-Attaque') return false
    },
    isSubCategoryVisible(subCategory){
      if (!this.rc && !['resp_civil_franchise_seche', 'resp_civile_seche'].includes(subCategory) ) return true

      let protectCyberGaranties = ['protect_cyber_assistance', 'protect_cyber_validation_donnees_personnelles',
        'protect_cyber_extorsion', 'protect_cyber_enquete', 'protect_cyber_franchise']

      let secheGaranties = ['resp_civil_franchise_seche']

      let globalGaranties = ['resp_civil_pro', 'information_juridique_tous_domaine']
      if (this.rc && this.jobName !== 'sb_domicile'
          && (protectCyberGaranties.includes(subCategory) || secheGaranties.includes(subCategory) || globalGaranties.includes(subCategory)) || subCategory === 'resp_civil_exp') return true

      return this.rc && this.jobName === 'sb_domicile' &&  (protectCyberGaranties.includes(subCategory) || secheGaranties.includes(subCategory) || globalGaranties.includes(subCategory) || subCategory === 'resp_civile_seche');
    },
    displayTable(category){
      let showTable = true;
      Object.keys(this.garanties.labels[category]).forEach((value) => {
        if (!this.isSubCategoryVisible(value) || !this.isCategorySelected(value)){
          showTable = true
        }
      });
      return showTable
    },
    isCategorySelected(category){

      return !category.includes('description') && this.garanties.garanties[this.jobName][category]['selected']
    },
    getRemboursementLabel(category, subCategory){
      return category === 'La protection de vos locaux profesionnels et leurs contenu'
          || (category === 'Garanties spécifiques à votre activité' && ['deterioration_marchandise_temp', 'dommage_materiels_transport', 'materiels_marchandise_exterieur_locaux_pro'].includes(subCategory))
    },
    getPdfKey(category){
      return category + (this.rc ? 'rc' : 'mrpro')
    },
    getJobPdfLink(pdfName){
      return this.garanties.garanties[this.jobName][pdfName]
    },
    getCouverturePdfLink(category){
      return this.garanties.labels[category]['module_couverture_pdf']
    },
    downloadPdf(pdfName){
      let event = {
        click_name: this.cleanPdfLink(pdfName),
        click_type: 'download',
        composant: 'pdf-garantie'
      };
      reloadEvent('click', event)
    },
    cleanPdfLink(pdfLink){
      var slugify = require('slugify')
      return slugify(decodeURI(pdfLink).split('garanties_pdf/')[1].replaceAll('%20', '-').replaceAll('%2C', '').replaceAll('%26', ''), {
        replacement: '-',
        lower: true,
      })
    }
  },
  computed: mapState([`needs`, `cyberProtect`]),
}
</script>

<style scoped>

</style>
