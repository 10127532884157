<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-11 col-lg-8 mt-4">
        <div class="row mb-5">
          <div class="col-12">

            <transition name="fade">
              <div>

                <h3 class="text-md-center title-page" v-html="formTexts.needs_cyber.title"></h3>

                <div class="row">
                  <div class="col-10 col-md-12 mt-4">
                    <div class="row mb-5">
                      <div class="col-12">
                        <RadioInput
                            :question="formTexts.needs_cyber.website"
                            v-model="cyber"
                            :submitted="submit"
                            :default-choice="cyber"
                            v-bind:class="{ columnDirection: true }"
                        ></RadioInput>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <FooterNavigation @submitStep="submitForm" @previousStep="previousStep"/>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FooterNavigation from "../../components/Navigation/FooterNavigation.vue";
import RadioInput from "hm-mpe-widgets/src/lib-components/components/Widgets/RadioInput.vue";
import wrapper from "../../main";
import { reloadEvent } from '../../services/tagCo';

export default {
  name: "NeedsCyber",
  components: { FooterNavigation, RadioInput },
  data: function () {
    return {
      cyber: null,
      submit: false,
    }
  },
  created() {
    this.updateValue()
    this.$store.commit("setNextRoute", "NeedsProtection");
    wrapper.setTcVars({
      page_name : 'protection-cyber',
      page_cat1_name : 'recueil-de-besoins',
      page_cat2_name : 'tarif-affine',
    });
    reloadEvent('page');
  },
  methods: {
    updateValue() {
      if(this.cyberProtect !== null) {
        this.cyber = this.cyberProtect ? 'oui' : 'non'
      }
    },
    submitForm() {
      this.submit = true
      this.$store.state.cyberProtect = this.cyber === "oui"
      if(this.cyber) {
        this.nextRoute()
      }
    },
    nextRoute() {
      this.$store.dispatch('updateAvancementNeeds', {
            avancementNumber: 2,
            action: true
        });
      this.$router.push({ name: this.$store.getters.getNextRoute });
    },
    previousStep() {
      if(this.local.nbBuildings === "00") {
        this.$router.push({ name: "FastPriceLocal"})
      } else if(this.local.nbBuildings === "1") {
        this.$router.push({ name: "NeedsLocal"})
      }
    }
  },
  computed: mapState([`formTexts`, `cyberProtect`, `local`]),
}
</script>
