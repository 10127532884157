import axios from "axios";
import {isProduction} from "@/services/WizardHelperService";

export default axios.create({
    baseURL: getOdsApiUrl(),
	headers: {
		"Accept": "application/json",
		"Content-Type": "application/json",
		'apiKey': '0F1B604C8838C7A00612B840B20A3CBAD19FB05FF10EE312745D1B8DDB35DC5A',
    },
    'auth': {
        username: 'DISTRI-WEB_USER1',
        password: 'vm5ZLPz8tloxCR2zXj'
    }
})

function getOdsApiUrl(){
    let apiUrl = 'https://rec.api.harmonie-mutuelle.fr/services/'
    if (isProduction()){
        apiUrl = 'https://api.harmonie-mutuelle.fr/services/'
    }
    return apiUrl
}
