<template>
  <ViewContainer>
    <template v-slot:viewBody>
      <div class="row mb-5">
        <div class="col-12">

          <transition name="fade">
            <div>

              <h3 class="text-md-center title-page" v-html="formTexts.needs_pro_infos.title"></h3>

              <Siret ref="siret" :input-group="true" :question-text="formTexts.needs_pro_infos.siret"
                :default-value="formPm.siret" :submitted="submit" :defaultWaitingSiret="this.defaultWaitingSiret"
                :displayColumn="true" :waitingSiret="this.formPm.siret == ''" @siretDetails="completeFields"
                @waitingSiret="switchWaitingSiret" :base-url="this.baseUrl" />

              <TextInput v-model="this.raisonSociale" ref="raisonSociale" :submitted="submit"
                :valid-input="validValueRs" :valid-format="this.validFormat" :default-value="formPm.raisonSociale"
                :question="formTexts.needs_pro_infos.raison_sociale" :displayColumn="true" @update:textInput="setRs"
                input-name="raison sociale" />

              <FormeJuridique :component-texts="formTexts.needs_pro_infos.forme_juridique"
                :default-value="formPm.formeJuridique" :displayColumn="true" :submitted="submit" ref="formeJuridique"
                class="formejuridique" />

              <FormeJuridique :component-texts="formTexts.needs_pro_infos.nb_salaries"
                :default-value="formPm.nbEmployees.index" :displayColumn="true" :submitted="submit" ref="nbEmployees"
                class="nombresalaries" />

              <Address ref="address" :component-texts="formTexts.needs_pro_infos.adresse_siege"
                :default-value="local.addressLocal" :displayColumn="true" />

              <div class="row">
                <div class="col-12 col-md-9 mb-3">

                  <ZipCodeAndCity :horizontal="horizontality" :componentsTexts="formTexts.needs_pro_infos.ville_siege"
                    :componentTextsZipCode="questionZipCode" :componentTextsCity="questionCity" ref="postalCodeAndCity"
                    :default-value-city="this.companyCity" :default-value-zip-code="this.companyZipCode" />

                </div>
              </div>

              <DateInput ref="dateInput" :default-value="formPm.createdDate"
                :component-texts="formTexts.needs_pro_infos.date_creation" :displayColumn="true"
                @focusout="checkSuccesDateCreation" :optional="true" />

              <div v-if="this.succesDateCreation" class="form-succes form-success-text"
                v-html="formTexts.needs_pro_infos.success_date_creation.text"></div>

              <h3 class="text-md-center title-page" v-html="formTexts.needs_pro_infos.title_info_perso.text"></h3>


              <Civility :component-texts="formTexts.needs_pro_infos.civilite" :default-value="formPm.civility"
                input-name="civility" ref="civility" :displayColumn="true" />

              <FirstName :component-texts="formTexts.needs_pro_infos.prenom" :displayColumn="true"
                :default-value="formPm.firstName" ref="firstName" />

              <LastName :component-texts="formTexts.needs_pro_infos.nom" :displayColumn="true"
                :default-value="formPm.lastName" ref="lastName" />

              <PhoneNumber :component-texts="formTexts.needs_pro_infos.telephone" :no-mandatory="false"
                :displayColumn="true" :default-value="formPm.phoneNumber" ref="phoneNumber" />

              <Email @focusout="checkMails()" :component-texts="formTexts.needs_pro_infos.email"
                :default-value="formPm.email" :redBorder="!identicalMails" input-name="email" ref="email"
                :displayColumn="true" />

              <Email @focusout="checkMails()" @change="checkMails()" :default-value="formPm.confirmEmail"
                :component-texts="formTexts.needs_pro_infos.confirmation_email" input-name="validation-email"
                :redBorder="!identicalMails" ref="confEmail" :displayColumn="true" />

              <div class="row">
                <div v-if="!identicalMails" class="pb-3 d-flex col-12 justify-content-start form-error form-error-text">
                  Les adresses emails ne correspondent pas
                </div>
              </div>

              <div class="row justify-content-start ps-5">
                <div class="col-md-6 form-check">
                  <input class="form-check-input" type="checkbox" v-model="formPm.checkEmail" id="confirmEmail" />
                  <label v-html="formTexts.needs_pro_infos.email_optin.text" class="form-check-label" for="confirmEmail"
                    style="font-size: 0.8rem" />
                </div>
              </div>

              <div class="row">

                <div v-if="!formPm.checkEmail && submit"
                  class="d-inline col-md-6 text-start form-error form-error-text">
                  Merci de cocher la case
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </template>
    <template v-slot:viewFooter>
      <FooterNavigation @submitStep="submitForm" @previousStep="previousStep" />
    </template>
  </ViewContainer>
</template>

<script>
import { mapState } from "vuex";
import TextInput from "hm-mpe-widgets/src/lib-components/components/Widgets/TextInput.vue";
import Siret from "../../../node_modules/hm-mpe-widgets/src/lib-components/components/FormPmPp/Siret.vue";
import FooterNavigation from "../../components/Navigation/FooterNavigation.vue";
import Civility from "../../../node_modules/hm-mpe-widgets/src/lib-components/components/FormPmPp/Civility.vue";
import FirstName from "../../../node_modules/hm-mpe-widgets/src/lib-components/components/FormPmPp/FirstName.vue"
import LastName from "../../../node_modules/hm-mpe-widgets/src/lib-components/components/FormPmPp/LastName.vue"
import PhoneNumber from "../../../node_modules/hm-mpe-widgets/src/lib-components/components/FormPmPp/PhoneNumber.vue"
import Email from "../../../node_modules/hm-mpe-widgets/src/lib-components/components/FormPmPp/Email.vue"
import DateInput from "../../../node_modules/hm-mpe-widgets/src/lib-components/components/Widgets/DateInput.vue"
import Address from "../../../node_modules/hm-mpe-widgets/src/lib-components/components/FormPmPp/Address.vue";
import FormeJuridique from "../../../node_modules/hm-mpe-widgets/src/lib-components/components/FormPmPp/FormeJuridique.vue";
import { buildPMData } from "@/services/contactAPI/contactCallsBuilder";
import { getInterfactionTarif } from "@/services/contactAPI/interactionService";
import { getTarifOR } from "@/services/contactAPI/contactORService";
import { getRaisonSociale } from "hm-mpe-widgets/src/services/utils";
import ZipCodeAndCity from "../../../node_modules/hm-mpe-widgets/src/lib-components/components/FormPmPp/ZipCodeAndCity.vue";
import ViewContainer from "@/components/Navigation/ViewContainer";
import wrapper from "../../main";
import { reloadEvent } from '../../services/tagCo';

export default {
  name: "NeedsCoordonate",
  components: { ZipCodeAndCity, TextInput, FooterNavigation, Siret, FirstName, LastName, Email, DateInput, Civility, Address, FormeJuridique, PhoneNumber, ViewContainer },
  data: function () {
    return {
      siret: null,
      waitingSiret: false,
      raisonSociale: null,
      formeJuridique: null,
      nbEmployees: null,
      addressSiege: null,
      zipCodeSiege: null,
      citySiege: null,
      createdDate: null,
      baseUrl: process.env.VUE_APP_DRUPAL_URL,
      civility: null,
      firstName: null,
      lastName: null,
      phoneNumber: null,
      hasPhone: false,
      email: null,
      confirmEmail: false,
      submitSiret: false,
      validValueRs: false,
      submit: false,
      validValueCity: false,
      validValueSiret: false,
      succesDateCreation: null,
      identicalMails: true,
      smsAgreement: null,
      emailAgreement: null,
      validFormat: null,
      horizontality: false,
      defaultWaitingSiret: false,
      companyCity: null,
      companyZipCode: null,
    }
  },
  created() {
    if (this.formPm.postalCodeAndCity.city) {
      this.companyCity = this.formPm.postalCodeAndCity.city
      this.companyZipCode = this.formPm.postalCodeAndCity.zipCode
    } else {
      this.companyCity = this.local.postalCodeAndCity.city
      this.companyZipCode = this.local.postalCodeAndCity.zipCode
    }
    this.horizontality = window.innerWidth > 768;
    this.questionZipCode = {
      text: "Code Postal"
    };
    this.questionCity = {
      text: "Ville"
    };
    if (this.formPm.raisonSociale !== '') {
      this.raisonSociale = this.formPm.raisonSociale
      this.validValueRs = true
      this.validFormat = true
    }
    if (this.formPm.waitingSiret) {
      this.defaultWaitingSiret = true;
    } else {
      this.defaultWaitingSiret = false;
    }
    this.siret = this.formPm.siret;
    this.$store.commit("setNextRoute", "NeedsPrice");
    this.$store.dispatch("createContactProcessus");

    if(this.newCompany) {
      this.succesDateCreation = true;
    }

    wrapper.setTcVars({
      page_name : 'coordonnees',
      page_cat1_name : 'recueil-de-besoins',
      page_cat2_name : 'tarif-affine',
    });
    reloadEvent('page');
  },
  mounted() {
    if (this.formPm.siret !== null && this.formPm.raisonSociale && this.formPm.formeJuridique && (this.formPm.nbEmployees.index !== false && this.formPm.nbEmployees.index !== null) && this.local.addressLocal && this.local.postalCodeAndCity && this.formPm.civility && this.formPm.firstName && this.formPm.lastName && this.formPm.phoneNumber && this.formPm.email && this.formPm.confirmEmail && this.formPm.checkEmail) {
      this.updateState();
    }
  },
  methods: {
    switchWaitingSiret(value) {
      if (value) {
        this.siret = ""
        this.$refs.siret.setText("");
        this.formPm.waitingSiret = true
      } else {
        this.formPm.waitingSiret = false
      }
    },
    completeFields(etablissement) {
      this.siret = etablissement.siret;
      console.log(etablissement)
      if (etablissement) {
        this.$refs.raisonSociale.forceText(
          getRaisonSociale(etablissement.uniteLegale)
        );

        this.$refs.dateInput.forceText(etablissement.dateCreationEtablissement)
        // this.$refs.codeNaf.setText(etablissement.uniteLegale.activitePrincipaleUniteLegale);
        this.$refs.postalCodeAndCity.forceText(etablissement.adresseEtablissement.codePostalEtablissement, etablissement.adresseEtablissement.libelleCommuneEtablissement);

        if (etablissement.adresseEtablissement.numeroVoieEtablissement !== null && etablissement.adresseEtablissement.libelleVoieEtablissement !== null) {
          this.$refs.address.forceText(etablissement.adresseEtablissement.numeroVoieEtablissement + " " + etablissement.adresseEtablissement.libelleVoieEtablissement);
          if (etablissement.uniteLegale.trancheEffectifsUniteLegale !== 'NN') {
            this.setEmployee(etablissement.uniteLegale.trancheEffectifsUniteLegale);
            this.$refs.nbEmployees.forceSelect(this.formPm.nbEmployees.index);
          }
          this.disabledSubmitBtnLabel = null;
          this.formPm.autiCompleteFields = true;
        }

        // if (etablissement.adresseEtablissement.codePostalEtablissement !== "") {
        //   this.completeCity(this.etablissement.adresseEtablissement.codePostalEtablissement);
        // }
        this.disabledSubmitBtnLabel = null;
        this.formPm.autiCompleteFields = true;

      } else {

        this.disabledInputs = false;
        this.siret = this.formPm.siret
        this.codeNaf = this.formPm.codeNaf
        this.raisonSociale = this.formPm.raisonSociale
        this.address = this.formPm.addressSiege
        this.zipCode = this.formPm.zipCodeSiege
        this.city = this.formPm.citySiege
        this.formJuridique = this.formPm.formJuridique;
        this.employee = this.formPm.nbEmployees.index;

        this.$refs.raisonSociale.forceText(this.raisonSociale);
        this.$refs.codeNaf.setText(this.codeNaf);
        this.$refs.zipCode.forceText(this.zipCode);
        this.$refs.city.setText(this.city);
        this.$refs.address.forceText(this.address);
        this.$refs.formJuridique.forceSelect(this.formJuridique)
        this.$refs.nbEmployees.setText(this.employee)
        this.formPm.autiCompleteFields = false;
      }
    },

    checkMails() {
      if (this.$refs.email && this.$refs.confEmail) {
        if (this.$refs.email.inputValue == null || this.$refs.confEmail.inputValue == null) {
          this.identicalMails = true;
          return true;
        }
        if (this.$refs.email.submitField() != this.$refs.confEmail.submitField()) {
          this.identicalMails = false;
          return false;
        } else {
          this.identicalMails = true;
          return true;
        }
      }
      return false;
    },
    setEmployee(employee) {
      this.employee = employee;
      this.formPm.nbEmployees.index = Object.keys(this.formTexts.needs_pro_infos.nb_salaries.choices[this.employee])[0];
      this.formPm.nbEmployees.value = this.formTexts.needs_pro_infos.nb_salaries.choices[this.employee][this.formPm.nbEmployees.index];
    },

    getEmployeeValue(index) {
      (this.formTexts.needs_pro_infos.nb_salaries.choices).forEach(element => {
        if (Object.keys(element)[0] === index) {
          this.formPm.nbEmployees.value = element[index]
        }
      })
    },

    setRs(value) {
      if (value !== "") {
        this.raisonSociale = value
        this.validValueRs = true
        this.validFormat = true
      } else {
        this.validValueRs = false
        this.validFormat = false
      }
    },

    checkSuccesDateCreation() {
      let date = new Date()
      // aaaa-mm-jj
      if (date - Date.parse(this.$refs.dateInput.submitField()) <= 15951600000) {
        this.succesDateCreation = true
      } else {
        this.succesDateCreation = false
      }
    },

    updateState() {
      this.formPm.siret = this.$refs.siret.inputValue
      this.formPm.raisonSociale = this.raisonSociale
      this.formPm.formeJuridique = this.$refs.formeJuridique.submitField()
      this.formPm.nbEmployees.index = this.$refs.nbEmployees.submitField()
      this.getEmployeeValue(this.formPm.nbEmployees.index)
      this.local.addressLocal = this.$refs.address.submitField()
      this.formPm.postalCodeAndCity = this.$refs.postalCodeAndCity.submitField()
      this.formPm.createdDate = this.$refs.dateInput.submitField()

      this.formPm.civility = this.$refs.civility.submitField()
      this.formPm.firstName = this.$refs.firstName.submitField()
      this.formPm.lastName = this.$refs.lastName.submitField()
      this.formPm.phoneNumber = this.$refs.phoneNumber.submitField()
      this.formPm.email = this.$refs.email.submitField()
      this.formPm.confirmEmail = this.$refs.confEmail.submitField()

      this.formPm.optin_sms = this.smsAgreement
      this.formPm.optin_email = this.emailAgreement
      if (this.formPm.siret === "") {
        this.formPm.waitingSiret = true
      }
    },

    saveConsentements() {
      let smsOptin = this.optins.typeCanaux.find((t) => {
        return t.libelle === "SMS";
      });
      let emailOptin = this.optins.typeCanaux.find((t) => {
        return t.libelle === "Email";
      });
      let formPmPpOptins = {
        ficheId: this.optins.id,
        canaux: [
          {
            typeCanalId: smsOptin.id,
            flagAcceptation: this.smsAgreement === "oui",
          },
          {
            typeCanalId: emailOptin.id,
            flagAcceptation: this.emailAgreement === "oui",
          },
        ],
      };
      this.$store.commit("setFormPmPpOptins", formPmPpOptins);
    },

    submitForm() {
      this.updateState()

      let apiContactData = buildPMData(this.$store.state, getInterfactionTarif(this.$store.state), getTarifOR(this.$store.state));
      this.$store.dispatch('createContact', apiContactData)
      this.$store.dispatch('setIsNewCompany', this.succesDateCreation);
      this.submit = true
      if (this.formPm.siret !== null && this.$refs.siret.submitField() !== false && this.formPm.raisonSociale && this.formPm.formeJuridique && (this.formPm.nbEmployees.index !== false && this.formPm.nbEmployees.index !== null) && this.local.addressLocal && this.local.postalCodeAndCity && this.formPm.civility && this.formPm.firstName && this.formPm.lastName && this.formPm.phoneNumber && this.formPm.email && this.formPm.confirmEmail && this.formPm.checkEmail) {
        this.$store.dispatch("createPrice")
        this.nextRoute()
      }
    },
    nextRoute() {
      this.$store.dispatch('updateAvancementNeeds', {
        avancementNumber: 4,
        action: true
      });
      this.$store.dispatch('updateAvancementNeeds', {
        avancementNumber: 5,
        action: true
      });
      this.$router.push({ name: this.$store.getters.getNextRoute });
    },
    previousStep() {
      this.$router.push({ name: "NeedsProtection" })
    }
  },
  computed: mapState([`formTexts`, `formPm`, `optins`, `local`, `newCompany`]),
}
</script>
