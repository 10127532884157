<template>

  <router-link
      custom
      v-slot="{ href, navigate, isActive }"
      :to="{ name: routeName }">

    <a class="py-2 px-md-2 link-container" v-if="complet" :href="href" @click="navigate"
       :data-name="routeName"
        :class="[{'col-7 router-link-active px-2' : isActive, 'col px-0' : !isActive}, linkColSize]">
    <div class="" :class="{'' : !isActive}">

      <div class="text-center d-inline">

        <span class="badge d-inline" ><i class="bi bi-check-circle-fill bg-badge-valid-default" :class="{ 'bg-badge-valid-active': isActive }" style="font-size: 22px"></i></span>
      </div>

      <div class="pt-lg-0 text-start d-md-inline text-black"
           :class="{'d-none ' : !isActive, '': isActive}">{{ linkText }}</div>
    </div>
    </a>

    <span class="py-2 link-container" v-if="!complet"
       :data-name="routeName"
       :class="[isActive ? 'router-link-active col-7' : 'col px-0', linkColSize, {'text-black' : complet} ]">
      <div class="" :class="{'' : !isActive}">

        <div class="text-center d-inline">
          <span class="badge rounded-pill bg-badge-default "
                :class="{ 'bg-badge-active': isActive, 'ps-2 pe-2': stepIndex != 1 }">{{ stepIndex }}</span>
        </div>

        <div class="text-start d-md-inline"
             :class="{'d-none fw-bold ms-1' : !isActive, 'ms-1 d-inline': isActive}">{{ linkText }}</div>
      </div>
    </span>


  </router-link>

</template>

<script>
import { mapState } from "vuex";
export default {
  name: "NavLink",
  props: {
    linkText: String,
    routeName: String,
    stepIndex: String,
    complet: {
      type: Boolean,
      default: false
    },
    linkColSize: {
      type: String,
      default: 'col'
    },
  },

    computed: mapState([`formTexts`]),
};
</script>

<style scoped>

</style>
