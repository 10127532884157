import { createRouter, createWebHistory } from "vue-router";
import OutView from "../views/OutView.vue";
import FastPriceActivity from "@/views/FastPrice/FastPriceActivity";
import FastPriceLocal from "@/views/FastPrice/FastPriceLocal";
import FastPrice from "@/views/FastPrice/FastPrice";
import Home from "@/views/Home";
import Needs from "@/views/Needs/Needs";
import NeedsLocal from "@/views/Needs/NeedsLocal";
import NeedsCyber from "@/views/Needs/NeedsCyber";
import NeedsProtection from "@/views/Needs/NeedsProtection";
import NeedsCoordonate from "@/views/Needs/NeedsCoordonate";
import NeedsPrice from "@/views/Needs/NeedsPrice"

const routes = [
  {
    path: '/',
    name: "Home",
    component: Home,
    redirect: {
      name: 'FastPrice'
    }
  },
  {
    path: '/tarif-rapide', name: 'FastPrice', component : FastPrice, redirect: {
      name: 'FastPriceActivity'
    }, children: [
      {
        path: 'activite',
        name: 'FastPriceActivity',
        component: FastPriceActivity
      },
      {
        path: 'local',
        name: 'FastPriceLocal',
        component: FastPriceLocal
      },
    ]
  },
  {
    path: '/vos-informations', name: 'Needs', component: Needs, redirect: {
      name: 'NeedsLocal'
    }, children: [
      {
        path: 'local',
        name: 'NeedsLocal',
        component: NeedsLocal
      },
      {
        path: 'cyber',
        name: 'NeedsCyber',
        component: NeedsCyber
      },
      {
        path: 'protection',
        name: 'NeedsProtection',
        component: NeedsProtection
      },
      {
        path: 'vos-coordonnees',
        name: 'NeedsCoordonate',
        component: NeedsCoordonate
      },
      {
        path: 'votre-tarif',
        name: 'NeedsPrice',
        component: NeedsPrice
      }
    ]
  },
  {
    path: "/sortie",
    name: "Sortie de parcours",
    component: OutView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    return {top: 0};
  }
});

export default router;
