<template>
  <div>
    <div v-if="!categoryIncludesDesc(category, subCategory)">
      {{ garantiesLabels[category][subCategory] }}
    </div>
    <div v-if="categoryIncludesDesc(category, subCategory)">
      <div class="accordion" id="accordionPanelsStayOpenExample">
        <div class="accordion-item bg-transparent d-flex align-items-center justify-content-start border-0">
          <div>{{ garantiesLabels[category][subCategory] }}</div>

          <a class="accordion-button collapsed w-auto bg-transparent pe-0 pt-0 pb-0"
             data-bs-toggle="collapse" :data-bs-target="'#panel'+subCategory"
             aria-expanded="false" :aria-controls="'panel'+subCategory" @click="knowMore()">
            En savoir plus
          </a>
        </div>
      </div>
      <div :id="'panel'+subCategory" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne">
        <div class="accordion-body" v-html="garantiesLabels[category][subCategory + '_description']"></div>
      </div>
    </div>
  </div>
</template>

<script>
import templateHelpers from "@/services/templateHelpers";
import { reloadEvent } from "../../services/tagCo";

export default {
  name: "GarantyAccordion",
  props:{
    category : null,
    subCategory : null,
    garantiesLabels: null,
  },
  data: function (){
    return {
      knowMoreClosed: true
    }
  },
  methods:{
    ...templateHelpers,
    categoryIncludesDesc(category, subCategory){
      return Object.keys(this.garantiesLabels[category]).includes(subCategory + '_description')
    },
    knowMore(){
      if (this.knowMoreClosed){
        let event = {
          click_name: 'en-savoir-plus-'+ this.convertToSlug(this.garantiesLabels[this.category][this.subCategory]),
          click_type: 'action',
          composant: 'descriptif-garantie'
        };
        reloadEvent('click', event)
      }
      this.knowMoreClosed = !this.knowMoreClosed
    }
  }
}
</script>

<style scoped>

</style>
