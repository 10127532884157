<template>
  <div class="fast-price-local">
    <ViewContainer>
      <template v-slot:viewBody>
        <div class="row mb-5">
          <div class="col-12">
            <div>
              <h3 class="text-md-center title-page" v-html="formTexts.fast_price_local.title"></h3>
              <div class="row">
                <div class="col-12 col-md-8 container-fluid">
                  <div class="row">
                    <div class="col-10 col-lg-11 mt-4">
                      <div class="row">
                        <div class="col-12">
                          <RadioInput :inputColSize="0" v-model="local.nbBuildings" :default-choice="local.nbBuildings"
                            :question="formTexts.fast_price_local.nb_local" :submitted="submit"
                            @update:modelValue="checkNbBuildings" v-bind:class="{ columnDirection: true }" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="local.nbBuildings === '1' && !this.mrProCourtage">
                    <div class="row">
                      <div class="col-12 col-md-12 mb-3">
                        <div class="input-group">
                          <SelectInput class="justify-content-center form-control p-0 border-0 d-flex"
                            v-model="local.superficie" :default-choice="local.superficie"
                            :question="formTexts.fast_price_local.superficie" :input-col-size="12" :submitted="submit"
                            :valid-input="validValue" @update:modelValue="checkSuperficie" />
                        </div>
                      </div>
                    </div>

                    <div class="row" v-if="this.local.superficie && this.local.superficie !== '500'">
                      <div class="col-12 mb-3">

                        <ZipCodeAndCity :horizontal="horizontality" :componentsTexts="formTexts.fast_price_local.ville"
                          :componentTextsZipCode="questionZipCode" :componentTextsCity="questionCity"
                          fromProject="mrpro" :plusSimpleCities="this.$store.state.local.cities"
                          placeholderZipcode="Code postal" placeholderCity="Sélectionnez votre ville"
                          :default-value-city="local.postalCodeAndCity.city"
                          :default-value-zip-code="local.postalCodeAndCity.zipCode" ref="postalCodeAndCity"
                          @change="zipCompleted()" />

                      </div>

                      <div v-if="completCity() || isCityCompleted" class="col-10 col-lg-12 mt-4">
                        <div class="row mb-5">
                          <div class="col-12">
                            <RadioInput :inputColSize="0" v-model="local.statusOwner" ref="status"
                              :default-choice="local.statusOwner"
                              :question="formTexts.fast_price_local.statut_local_user" :submitted="submit"
                              @update:modelValue="checkOwner" v-bind:class="{ columnDirection: true }" />
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>



                  <div class="col-auto nextbtn pt-4 footer-navigation d-none" id="kameleoonABTest">
                    <a class="btn btn-info rounded-pill needs-devis-button" :class="{ 'disabled': this.isDisabled }"
                      @click="nextStepAbTest()" data-cy="next-step">
                      <span class="me-3 ms-2">Suivant</span>
                      <img :src="require('@/assets/svg/arrow-title-white.svg')" />
                    </a>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:viewFooter>
        <FooterNavigation @submitStep="submitForm" @previousStep="previousStep"/>
      </template>
    </ViewContainer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FooterNavigation from "../../components/Navigation/FooterNavigation.vue";
import RadioInput from "hm-mpe-widgets/src/lib-components/components/Widgets/RadioInput.vue";
import SelectInput from "../../../node_modules/hm-mpe-widgets/src/lib-components/components/Widgets/SelectInput.vue";
import ZipCodeAndCity from "../../../node_modules/hm-mpe-widgets/src/lib-components/components/FormPmPp/ZipCodeAndCity.vue";
import ViewContainer from "@/components/Navigation/ViewContainer";
import wrapper from "../../main";
import { reloadEvent } from '../../services/tagCo';


export default {
  name: "FastPriceLocal",
  components: { ViewContainer, FooterNavigation, RadioInput, SelectInput, ZipCodeAndCity },
  data: function () {
    return {
      city: null,
      cityCompleted: null,
      isZipCompleted: false,
      isCityCompleted: false,
      submit: false,
      validValue: false,
      questionZipCode: false,
      questionCity: false,
      horizontality: false,
      isDisabled: true,
      iconNames: ['job_specfic_garantees', 'perte_exploit_garanty', 'protection_juridique_garanty', 'remise_entrepreneur'],
      plc: null
    }
  },
  created() {
    if (this.$store.state.avancement[1] !== true) {
      this.$router.push({ name: "FastPrice" });
    } else {
      this.$store.dispatch('checkActivityCourtage', this.activity.prospectActivity.split(',')[0]);
      this.horizontality = window.innerWidth > 768;
      this.questionZipCode = {
        text: "Code Postal"
      };
      this.questionCity = {
        text: "Ville"
      };

      if (this.$store.state.local.postalCodeAndCity.zipCode) {
        this.isZipCompleted = true;
      }
      if (this.$store.state.local.postalCodeAndCity.city) {
        this.isCityCompleted = true;
      }
      this.updateValue();
      if (this.local.superficie !== null) {
        this.validValue = true;
      }
      if (this.activity.previousCa || this.activity.previousActivity) {
        if (this.activity.ca !== this.activity.previousCa || this.activity.previousActivity !== this.activity.prospectActivity) {
          this.local.nbBuildings = null
          this.local.statusOwner = null
          this.local.superficie = null
          this.local.postalCode = null
          this.city = null
          this.submit = false
          this.validValue = false
          this.$store.state.fastPrice = null
          this.$store.state.prospect = null
        }
      }
      this.$store.commit("setNextRoute", "NeedsLocal");
      if (this.local.nbBuildings !== null || this.local.statusOwner !== null) {
        this.isDisabled = false;
      }
    }
    wrapper.setTcVars({
      page_name : 'local',
      page_cat1_name : 'recueil-de-besoin',
      page_cat2_name : 'tarif-rapide',
    });
    reloadEvent('page');
  },
  methods: {
    async zipCompleted() {

      this.isZipCompleted = true;
      this.fastPrice = null
      this.$store.state.prospect = null
      this.isCityCompleted = false
      this.$store.state.local.statusOwner = null
      await this.$store.dispatch('getCities', this.$refs.postalCodeAndCity.getZipCode());

      this.completCity();
      this.$refs.status.forceSelect(this.local.statusOwner);
    },
    completCity() {
      if (this.isZipCompleted && this.$refs.postalCodeAndCity && this.$refs.postalCodeAndCity.submitField() && this.$refs.postalCodeAndCity.submitField().city !== undefined && this.$refs.postalCodeAndCity.submitField().city !== "") {
        return true
      } else {
        this.fastPrice = null
        if (!this.isCityCompleted) {
          this.local.statusOwner = null
        }

        return false
      }
    },
    updateValue() {
      this.city = this.$store.state.formPm.city
    },
    submitForm() {
      this.updateState()
      this.activity.previousCa = null
      this.activity.previousActivity = null
      if (this.local.superficie) {
        this.validValue = true
      }
      this.submit = true
      if (this.local.nbBuildings === "00") {
        this.$store.commit("setNextRoute", "NeedsCyber");
        this.nextRoute()
      } else if (this.$refs.postalCodeAndCity.submitField()) {
        this.local.postalCodeAndCity = this.$refs.postalCodeAndCity.submitField()
      }
      if(this.local.statusOwner !== null) {
        this.$store.commit("setNextRoute", "NeedsLocal");
        this.nextRoute();
      }
      if (this.local.nbBuildings === "2" || this.local.superficie === "500" || this.$store.state.isCourtage) {
        this.$store.dispatch('closeOrOpenModal', {
          modalName: 'ModalWcb',
          action: true
        })
      }
    },
    updateState() {
      if (this.nbBuildings === "1") {
        if (this.local.superficie) {
          // this.$store.state.formPm.city = this.$refs.city.submitField()
          this.fastPrice = null
          this.$store.dispatch('updateProspect', null);
        }
      }
    },
    checkNbBuildings() {
      this.submit = false
      if (this.rcCourtage === true && this.local.nbBuildings === "00") {
        console.log('in rc courtage')
        this.$store.dispatch('closeOrOpenModal', {
          modalName: 'ModalWcb',
          action: true
        })
      } else if(this.mrProCourtage && this.local.nbBuildings === "1") {
        console.log('in mr courtage')
        this.$store.dispatch('closeOrOpenModal', {
          modalName: 'ModalWcb',
          action: true
        })
      }else if(this.local.nbBuildings === "1") {
        this.isDisabled = true;
        this.updateState()
        this.$store.dispatch("resetApiData")
      } else if (this.local.nbBuildings === "00" && !this.$store.state.isCourtage) {
        this.isDisabled = false;
        this.local.statusOwner = null
        this.updateState()
      } else if (this.local.nbBuildings === "2") {
        this.$store.dispatch('closeOrOpenModal', {
          modalName: 'ModalWcb',
          action: true
        })
        this.$store.dispatch("resetApiData")
        wrapper.setTcVars({
          page_name : 'trop-de-locaux',
          page_cat1_name : 'exclusion',
          page_cat2_name : '',
        });
        reloadEvent('page');
      }
      this.setProduct()
    },
    checkSuperficie() {
      this.submit = false
      this.validValue = this.local.superficie !== ""

      if (this.local.superficie === "500") {
        this.$store.dispatch('closeOrOpenModal', {
          modalName: 'ModalWcb',
          action: true
        })
        if (this.local.superficie === "500") {
          wrapper.setTcVars({
            page_name : 'superficie-trop-haute',
            page_cat1_name : 'exclusion',
            page_cat2_name : '',
          });
          reloadEvent('page');
        }
      }
      if (this.local.statusOwner) {
        this.checkOwner()
      }
    },
    checkOwner() {
      this.local.postalCodeAndCity = this.$refs.postalCodeAndCity.submitField();
      this.isDisabled = false;
      this.submit = false
      this.updateState()
    },
    setProduct() {
      if (this.local.nbBuildings === "1") {
        this.activity.product = "mrp"
        this.activity.productOds = "MRPRO"
        this.activity.isMrPro = true
      } else if (this.local.nbBuildings === "00") {
        this.activity.product = "rc"
        this.activity.productOds = "RC"
        this.activity.isMrPro = false
      }
      this.$store.dispatch("getProduct").then(() => {
        if (this.$store.state.needs[0].codeProduitCommercial.includes("COURTAGE")) {
          this.$store.state.isCourtage = true
          this.$store.state.prospect = null
          this.$store.dispatch('closeOrOpenModal', {
          modalName: 'ModalWcb',
          action: true
        })
        } else {
          this.$store.state.isCourtage = false
        }
      })
    },
    nextRoute() {
      //this.$store.state.avancement[2] = true;
      this.$store.dispatch('updateAvancement', {
        avancementNumber: 2,
        action: true
      })
      this.$router.push({ name: this.$store.getters.getNextRoute });
    },
    previousStep() {
      this.$router.push({ name: "FastPriceActivity" })
    },
    submitStep() {
      this.$emit("submitStep");
    },
    saveZipCity() {
      this.local.postalCodeAndCity = this.$refs.postalCodeAndCity.submitField()
    },
    nextStepAbTest() {
      if (this.local.nbBuildings === "00") {
        this.$store.commit("setNextRoute", "NeedsCyber");
      } else {
        this.local.postalCodeAndCity = this.$refs.postalCodeAndCity.submitField()
      }
      this.nextRoute()
    }
  },
  computed: mapState([`formTexts`, `local`, `activity`, `fastPrice`, `rcCourtage`, `mrProCourtage`]),
};
</script>
