<template>
  <div class="contact">
    <a @click="showModal()">
      <img class=" callBackImg"
           :src="require('@/assets/svg/icon-callback.svg')"/>
    </a>
  </div>

  <ModalWCB
      id="modalCallBack"
      ref="modalCallBack"
  />
</template>

<script>

import { mapState } from "vuex";
import ModalWCB from "@/components/WebCallBack/ModalWCB";
import { reloadEvent } from "../../services/tagCo";

export default {
  name: "Contact",
  components: { ModalWCB},
  methods: {
    showModal(){
      this.$store.dispatch('setTitleWcbValue', 'Contacter un conseiller Harmonie Mutuelle')
      let event = {
            click_name: 'clic-contact',
            click_type: 'action',
            composant: 'sticky'
          };
          reloadEvent('click', event)
      this.$store.dispatch('setModalWcb', true)
    }
  },
  computed: mapState([`formTexts`]),
};
</script>

