import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import TC_Wrapper from 'vue-tag-commander';
import {isProduction} from "@/services/WizardHelperService";

window.tc_vars = {
    env_work : isProduction() ? 'prod' : 'preprod',
    env_url : window.location.host,
    page_name : '',
    page_cat1_name : '',
    page_cat2_name : '',
    page_cat3_name : '',
    page_level_name : 'parcours-multirisques-pro',
    goal_type : '',
};

const wrapper = TC_Wrapper.getInstance()
// Set debug for development purpose if needed
wrapper.setDebug(true)
// Add TC Containers

let urlTagCoHeader;
let urlTagCoFooter;

if(isProduction()) {
  urlTagCoHeader = "//cdn.tagcommander.com/5013/tc_Parcoursdevente_header.js"
  urlTagCoFooter = "//cdn.tagcommander.com/5013/tc_Parcoursdevente_footer.js"
} else {
  urlTagCoHeader = "//cdn.tagcommander.com/5013/uat/tc_Parcoursdevente_header.js"
  urlTagCoFooter = "//cdn.tagcommander.com/5013/uat/tc_Parcoursdevente_footer.js"
}

Promise.all([
  wrapper.addContainer("container_head", urlTagCoHeader, "head"),
  wrapper.addContainer("container_body", urlTagCoFooter, "body"),
]).then(() => {

    require("bootstrap");

    createApp(App).use(store).use(router).mount("#app");

})

export default wrapper;