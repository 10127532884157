<template>
  <div class="swiper-slide" :class="{'col-12 col-lg-4': !isMobil, 'col-4': stickyMobil }" v-for="(offer) in offersTexts" :key="offer.product">
    <OfferCard :advicedOffer="selectedOffer" :offer-id="offer.product" :class="[selectedOffer !== offer.product ? 'pt-5' : '']">
      <template v-slot:top-header>
        <div class="row justify-content-center">
          <div class="col-11">
            <div class="adviced-offer">D’après vos besoins nous vous conseillons ce niveau de protection</div>
          </div>
        </div>
      </template>
      <template v-slot:header>
        <div class="row gx-0 gx-md-2 justify-content-center">
          <div class="col-12">
            <h4 class="offer-title text-center mb-0">{{ offer.name }}</h4>
          </div>
        </div>
      </template>
      <template v-slot:body>
        <div class="row gx-0 gx-md-2">
          <div class="col-12">
            <Loading
                :active="!this.offersTexts"
                :is-full-page="false"/>
            <MonthPrice v-if="price[offer.product]" :show-asterisk="cyberProtect" :price="this.cyberProtect? (price[offer.product].price + 16.35).toFixed(2).toString().replace('.',',') : price[offer.product].price.toString().replace('.',',')" />
            <div class="cyber-label text-center" v-if="this.cyberProtect">Dont <strong>16,35€</strong> de <strong>Protection Cyber</strong></div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="row gx-0 gx-md-2 justify-content-center">
          <div class="col-12 my-3 mx-auto text-center">
            <OfferSelectButton :class="{ 'tarif-not-ready': offer.loading }"
                               :offer="offer.product" cotisation-structure="cotisationStructure"
                               :ref="'flexRadioDefaultOffer' + offer.product"
                               :name="'flexRadioDefaultOffer' + offer.product"
                               default-choice="mpe110" :selected="selectedOffer"
                               @click="this.openModal(offer.product)"
                               mobile-select-offer-label="Sélectionner"
                               mobile-selected-offer-label="Sélectionnée"
                               @update:modelValue="itemSelected(offer)" />
          </div>
          <div class="col-11 col-md-10">
            <p v-if="this.newCompany" class="annual-tarif mb-0">*Tarif sur l’année en cours. La remise créateur d’entreprise sera appliquée sur ce tarif la première année de contrat</p>
            <p v-else class="annual-tarif mb-0">*Tarif sur l’année en cours.</p>
          </div>
          <div class="col-11 col-md-10">
            <DevisButton v-if="selectedOffer === offer.product" :in-nav="false" send-devis-class="d-none" subscribe-class="col-12"/>
          </div>
        </div>
      </template>
    </OfferCard>
  </div>

</template>

<script>
import MonthPrice from "hm-mpe-widgets/src/lib-components/components/Devis/MonthPrice.vue";
import Loading from 'vue-loading-overlay';
import OfferSelectButton from "../../node_modules/hm-mpe-widgets/src/lib-components/components/Offers/OfferSelectButton.vue";
import OfferCard from "../../node_modules/hm-mpe-widgets/src/lib-components/components/Widgets/OfferCard.vue";
import {mapState} from "vuex";
import DevisButton from "@/components/NeedsPrice/DevisButton";

export default {
  name: "OffersCardsDeck",
  components: {DevisButton, MonthPrice, Loading, OfferCard, OfferSelectButton },
  props: {
    price: null,
    jobName : null,
    rc : {
      type : Boolean,
      default : false
    },
  },
  data: () => {
    return {
      selected: null
    }
  },
  created() {
    this.selected = this.$store.state.offer
  },
  methods: {
    itemSelected(offer) {
      this.$store.commit('setSelectedOffer', offer.product)
    },
    // Retire les accents du nom de l'offre
    getOfferPdf(offer){
      return offer.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "") + (this.rc ? "rc" : "mrpro")
    },
    openModal(offer) {
      if(this.adviceOffer === 'essentielle' || (this.adviceOffer === 'equilibre' && this.selectedOffer === 'serenite')) {
        this.$store.dispatch('isBetterOffer', true)
      } else {
        this.$store.dispatch('isBetterOffer', false)
      }
      if(this.adviceOffer != offer){
        this.$store.dispatch('closeOrOpenModal', {
        modalName: 'ModalChangeOffer',
        action: true
        })
      }
    }
  },
  computed: mapState([`garanties`, `cyberProtect`, `offersTexts`, `selectedOffer`, `previousOffer`, `adviceOffer`, `isMobil`, `stickyMobil`, `newCompany`]),
}
</script>

<style scoped>

</style>
