<template>
  <div class="d-flex justify-content-start align-items-center">
    <div class="pe-3 rounded d-inline-block order-0">
      <label class="switch" >
        <input type="checkbox"
               v-model="cyberProtect"
               @input="toggleCyber()">
        <span class="toggle-switch round"></span>
      </label>
    </div>
    <div class="d-inline-block">
      <strong><div v-html="label" :class="[ navBar ? 'd-block' : 'd-inline-block']"></div></strong>
      <div v-if="cyberProtect" class="order-2 cyberProtectPrice text-info2"
           :class="[ navBar ? 'd-block' : 'd-inline-block']"> + 16,35€/mois</div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "CyberProtectSwitch",
  props: {
    // Vaut false dans le cas où c'est le switch de la navbar
    // Depuis les maquettes v4 inputFirst = true meme pour la navBar
    inputFirst : {
      type: Boolean,
      default: true
    },
    label: null,
    navBar: {
      type: Boolean,
      default: false
    },
    bluePrice: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    toggleCyber(){
      this.$store.commit('toggleCyberProtect')
    }
  },
  computed: mapState([`formTexts`, `cyberProtect`]),
}
</script>

<style scoped>

</style>
