<template>

  <nav class="price-navbar navbar navbar-light bg-light sticky-bottom fixed-bottom" v-if="this.garanties">
    <div class="container-fluid d-block">
      <div class="row justify-content-center">

        <div class="col-12 col-xxl-10">

          <div class="row align-items-center">
            <div class="d-none d-lg-block col-lg-7">
              <div class="row align-items-center">
                <div class="col-md-2 col-lg-3 offer">
                  <strong>{{ 'Votre offre :'.toUpperCase() }}</strong><br>
                  <p class="text-info2">{{ this.offer[selectedOffer] }}</p>
                </div>
                <div class="col-md-3 col-lg-4 col-xl-4">
                  <MonthPrice :show-asterisk="cyberProtect" :price="this.cyberProtect? (price[selectedOffer].price + 16.35).toFixed(2).toString().replace('.',',') : price[selectedOffer].price.toString().replace('.',',')"/>
                </div>
                <div class="col-md-7 col-lg-5 d-none d-md-block">
                  <CyberProtectSwitch :label="formTexts.needs_price.protection_cyber_navbar.text" :nav-bar="true" />
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-5">
            <DevisButton :inNav="true" send-devis-class="d-none col-md-6 d-md-block" ></DevisButton>
          </div>
          </div>

        </div>
      </div>

    </div>
  </nav>
</template>

<script>
import {mapState} from "vuex";
import CyberProtectSwitch from "@/components/NeedsPrice/CyberProtectSwitch";
import MonthPrice from "hm-mpe-widgets/src/lib-components/components/Devis/MonthPrice.vue";
import DevisButton from "./DevisButton.vue";

export default {
  name: "PriceNavbar",
  components: { CyberProtectSwitch, MonthPrice, DevisButton },
  data: function() {
    return {
      offer: {
        'essentielle': 'Essentielle',
        'equilibre': 'Equilibre',
        'serenite': 'Sérénité'
      },
    }
  },
  methods: {
    subscribeOffer(){

    }
  },
  computed: mapState([`garanties`, `formTexts`, `cyberProtect`, `selectedOffer`, `price`]),
}
</script>

<style scoped>

</style>
