// import {isProduction} from "@/services/WizardHelperService";

export function initTagCo(){
	if(typeof tC !== 'undefined') {
		// eslint-disable-next-line
		console.log(tC.internalvars);
	}
}

export function reloadEvent(type, data = {}) {
	if(type === 'click') {
		window.tC.event.click(this, data);
	} else if(type === 'page') {
		window.tC.event.page(this, data);
	}
}