
export function buildPPData(state, interaction, OR){
    let callApiRes = getContactPPData(state, interaction, state.contact.idProcess, OR);
    return {
        apiContact : callApiRes,
        uuid : state.uuid,
        state : state
    }
}

export function buildInteractionOR(state, interaction, OR){
    let contactData = {
        interaction : interaction,
    };
    if (state.formPmPp.phoneNumber){
        contactData['opportuniteRelationnelle'] = OR
    }
    return contactData
}

export function buildPMData(state, interaction, OR){
    return getContactPMData(state, interaction, state.contact.idProcess, OR);
}

export function getContactPPData(state, interaction, idProcess, OR) {
    let contactPPData = {
        contactPP : getContactPP(state),
        interaction : interaction,
        flagEntrepreneur : true,
        idProcessus: idProcess,
    };
    if (state.formPm.phoneNumber){
        contactPPData['opportuniteRelationnelle'] = OR
    }
    return contactPPData
}

export function getContactPMData(formDatas, interaction, idProcess, OR){
    let PMData = {
        contactPM : getContactPM(formDatas),
        flagEntrepreneur : true,
        flagRedirection: true,
        idProcessus: idProcess,
    }
    if (interaction){
        PMData['interaction'] = interaction
    }
    if(OR){
        PMData['opportuniteRelationnelle'] = OR
    }
    return PMData
}

export function getContactPP(state){
    let pp = {
        "nom": state.formPm.lastName,
        "prenom": state.formPm.firstName,
        "dateNaissance": "01-04-1993", // TODO - state.formPm.birthDate,
        "civilite": state.formPm.civility === 'mr' ? 'M' : 'Mme',
        "adresse": {
            "voie": state.formPm.address,
            "complementAdresse": "",
            "batResidence": "",
            "lieuDit": "",
            "codePostal": state.formPm.postalCode,
            "commune": state.formPm.city,
            "pays": "France"
        },
        "email": {
            "type": "Personnelle",
            "adresse": state.formPm.email
        },
        "consentement": state.optins
    }

    if (state.phoneNumber) {
        var type = "Domicile";
        if(state.phoneNumber.charAt(1) == '6' || state.phoneNumber.charAt(1) == '7'){
            type = "Portable"
        } 
        pp["telephone"] = {
            "type": type,
            "numero": state.phoneNumber
        }
    }

    return pp;
}

function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

function formatDate(date) {
    return (
        [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate())
        ].join('-') +
        'T' +
        [
            padTo2Digits(date.getHours()),
            padTo2Digits(date.getMinutes()),
            padTo2Digits(date.getSeconds()),
        ].join(':') + 'Z'
    );
}

export function getContactPM(state){
    var type = "Domicile";
        if(state.formPm.phoneNumber.charAt(1) == '6' || state.formPm.phoneNumber.charAt(1) == '7'){
            type = "Portable"
        } 

    let contactPM = {
        "raisonSociale": state.formPm.raisonSociale,
        "codeNaf": state.activity.prospectActivity.split(',')[0].substr(0,5),
        "libelleNaf": getLibelleNaf(state.activity.prospectActivity.split(',')[0], state.nafList),
        "convCollIdcc": "9999",
        "libelleConvColl": "ABSENCE DE CONVENTION COLLECTIVE",
        "formeJuridique" : getFormeJuridique(state.formPm.formeJuridique),
        "trancheEffectifEtab": state.formPm.nbEmployees.value,
        "trancheEffectifGroupe": state.formPm.nbEmployees.value,
        "flagSiege": true,
        "telephonePM": {
            "type":type,
            "numero": state.formPm.phoneNumber
        },
        "emailPM": {
            "adresse": state.formPm.email
        },
        "adressePM": {
            "voie": state.local.addressLocal,
            "codePostal": state.formPm.postalCodeAndCity.zipCode,
            "commune": state.formPm.postalCodeAndCity.city,
            "pays": "France"
        }
    }
    if (!state.formPm.waitingSiret){
        contactPM['siret'] = state.formPm.siret
    } else {
        contactPM["flagSiretEnCours"] = state.formPm.waitingSiret
    }
    if(state.formPm.createdDate !== false){
        contactPM['dateCreation'] = formatDate(new Date(state.formPm.createdDate))
    }

    return contactPM;
}

function getLibelleNaf(codeNaf, nafList){
    let naf = nafList.find(n => n.id === codeNaf);
    return naf.text;
}

function getFormeJuridique(key){
    let formeJurifiqueValues = {
        "artisan": "Artisan",
        "artisant_commercant": "Artisan-commerçant",
        "associe_gerant_societe": "Associé gérant de Société",
        "commercant": "Commerçant",
        "eurl": "SARL unipersonnelle",
        "sarl": "Soc. à responsabilité limit",
        "autre": "Autre pers. morale droit privé"
    };
    return formeJurifiqueValues[key];
}
