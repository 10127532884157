<template>
  <!-- Modal -->
  <div
      class="modal modal_contact fade"
      :id="id"
      :ref="id"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      :aria-labelledby="id"
      aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" style="max-width: 700px">
      <div class="modal-content pt-3 pb-4">
        <div class="modal-header text-center border-0">
          <button
              type="button d-flex justify-content-end"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="this.$store.state.modalActivityHC = false"
          ></button>
        </div>
        <div class="modal-header text-center pt-0 border-0">
          <p v-if="this.title" class="modal-title head-modal w-100 fw-bolder">Compte tenu de votre activité, nous regrettons de ne pas pouvoir vous proposer un tarif.</p>
        </div>
        <div class="m-5 modal-body body">
          <div class="row">
            <div class="col-md-12">
              <strong>
                <a href="https://www.harmonie-mutuelle.fr/entrepreneurs" target="_blank">
                    <div class="mainText text-center">
                        Vous pouvez retrouver nos autres offres ici
                    </div>
                </a>
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

const bootstrap = require("bootstrap");

export default {
    name: "ModalHC",
    props: {
        id: String,
        title: {
            type: String,
            default: 'test'
        },
    },
    data: function () {
    return {
      myModal: null,
      wcbLink: null,
    };
  },
  created() {
    this.wcbLink = this.formTexts.wbc_modal.wcb_callback_link.link
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('ca')){
      this.wcbLink = this.wcbLink + '?ca=' + urlParams.get('ca')
    }
  },
  methods: {
    showModal() {
      this.myModal = new bootstrap.Modal(document.getElementById(this.id), {
        keyboard: false,
      });
      this.myModal.show();
    },
    confirm() {
      this.$emit("confirmAction", true);
      window.open(this.wcbLink, '_blank').focus();
    },
  },
  computed: {
    ...mapState([`formTexts`, `modalActivityHC`])
  },
  watch: {
    modalActivityHC() {
      if(this.modalActivityHC) {
        this.showModal()
      }
    }
  },
}
</script>

<style>

</style>